import * as yup from "yup";
import { useAtomValue } from "jotai";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";

import { Methods } from "api/api/v1/lessons/_lessonId@string/lesson_meeting_possible_datetimes/select_datetime";

import { lessonMeetingPossibleDatetimesAtom } from "features/teachers/stores/lessonsStore";

dayjs.extend(isBetween);

type postSelectMeetingPossibleDatetimesSchemaType = Methods["post"]["reqBody"];

export const getPostSelectMeetingPossibleDatetimesSchema =
  (): yup.ObjectSchema<postSelectMeetingPossibleDatetimesSchemaType> => {
    const lessonMeetingPossibleDatetimes = useAtomValue(
      lessonMeetingPossibleDatetimesAtom
    );

    return yup.object().shape({
      meeting_start_at: yup
        .string()
        .required("日時を選択してください")
        .test(
          "is-possible-datetime",
          "日時が選択可能な範囲を外れています",
          (value) => {
            if (value === undefined) return false;
            if (lessonMeetingPossibleDatetimes === undefined) return false;

            const filteredArray = lessonMeetingPossibleDatetimes.filter(
              (lessonMeetingPossibleDatetime) => {
                const possibleDatetimeFrom = dayjs(
                  lessonMeetingPossibleDatetime.possible_datetime_from
                );
                const possibleDatetimeTo = dayjs(
                  lessonMeetingPossibleDatetime.possible_datetime_to
                );
                const selectedDatetime = dayjs(value);
                return selectedDatetime.isBetween(
                  possibleDatetimeFrom,
                  possibleDatetimeTo.subtract(1, "hour"),
                  "minute",
                  "[]"
                );
              }
            );

            return filteredArray.length > 0;
          }
        ),
      is_all_reject: yup.boolean().required("全て拒否するか選択してください")
    });
  };
