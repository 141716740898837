import Axios from "axios";
import aspida from "libs/aspida";
import useAspidaSWR from "@aspida/swr";
import { useAtom } from "jotai";
import { useHistory } from "react-router-dom";

import { useAlertMessages } from "hooks/useAlertMessages";

import { studentQuestionnairesDataAtom } from "features/schools/store/studentQuestionnairesStore";

import { beforeQuestionnairesFormSchemaType } from "features/schools/constants/lessons/questionnaires/beforeQuestionnairesFormSchema";
import { afterQuestionnairesFormSchemaType } from "features/schools/constants/lessons/questionnaires/afterQuestionnairesFormSchema";
import {
  beforeQuestionnairesProductionFormKeys,
  beforeQuestionnairesDevelopmentFormKeys
} from "features/schools/constants/lessons/questionnaires/beforeQuestionnairesFormKeys";
import {
  afterQuestionnairesProductionFormKeys,
  afterQuestionnairesDevelopmentFormKeys
} from "features/schools/constants/lessons/questionnaires/afterQuestionnairesFormKeys";

import {
  ContactCorsProxyLocal,
  ContactCorsProxyNetwork
} from "constants/ContactFormKeys";

const useQuestionnairesApi = (lessonId: string, unique_id: string) => {
  const history = useHistory();
  const { addErrorMessage } = useAlertMessages();

  const [studentQuestionnairesData, setStudentQuestionnairesData] = useAtom(
    studentQuestionnairesDataAtom
  );

  const CorsProxy =
    process.env.REACT_APP_BUILD_ENV === "development"
      ? ContactCorsProxyLocal
      : ContactCorsProxyNetwork;
  const beforeQuestionnairesFormKeys =
    process.env.REACT_APP_BUILD_ENV === "production"
      ? beforeQuestionnairesProductionFormKeys
      : beforeQuestionnairesDevelopmentFormKeys;
  const afterQuestionnairesFormKeys =
    process.env.REACT_APP_BUILD_ENV === "production"
      ? afterQuestionnairesProductionFormKeys
      : afterQuestionnairesDevelopmentFormKeys;

  const { isLoading } = useAspidaSWR(
    aspida({}).api.v1.lessons._lessonId_string(lessonId).student_questionnaires
      .new,
    {
      onSuccess: (data) => {
        setStudentQuestionnairesData(data);
      },
      onError: (error) => {
        if (error.response?.status === 401) {
          addErrorMessage("URLが間違っています。先生に確認してください。");
          return;
        }
        addErrorMessage("アンケート情報の取得に失敗しました");
      },
      query: {
        unique_id
      }
    }
  );

  const submitBeforeQuestionnaireForm = async (
    data: beforeQuestionnairesFormSchemaType
  ) => {
    if (!studentQuestionnairesData) {
      addErrorMessage("アンケート情報の取得に失敗しているため送信できません");
      return;
    }

    const formattedData = new FormData();

    formattedData.append(
      beforeQuestionnairesFormKeys.lessonRequestIdKeys,
      studentQuestionnairesData.lesson_request_id?.toString() || ""
    );
    formattedData.append(
      beforeQuestionnairesFormKeys.schoolPrefectureNameKeys,
      studentQuestionnairesData?.school_prefecture_name || ""
    );
    formattedData.append(
      beforeQuestionnairesFormKeys.schoolNameKeys,
      studentQuestionnairesData?.school_name || ""
    );

    formattedData.append(
      beforeQuestionnairesFormKeys.schoolClassKeys,
      data.schoolClass
    );
    formattedData.append(
      beforeQuestionnairesFormKeys.studentIDNumberKeys,
      data.studentIDNumber
    );
    formattedData.append(
      beforeQuestionnairesFormKeys.isSurveyKeys,
      data.isSurvey
    );
    formattedData.append(
      beforeQuestionnairesFormKeys.howMuchFunKeys,
      data.howMuchFun
    );
    formattedData.append(
      beforeQuestionnairesFormKeys.wantToLearnKeys,
      data.wantToLearn
    );

    Axios.post(CorsProxy + beforeQuestionnairesFormKeys.formUrl, formattedData)
      .then(() => {
        setStudentQuestionnairesData(undefined);
        history.push("student_before_questionnaires/complete");
      })
      .catch((error) => {
        console.log(error.response);
        addErrorMessage("アンケートの送信に失敗しました");
      });
  };

  const submitAfterQuestionnaireForm = async (
    data: afterQuestionnairesFormSchemaType
  ) => {
    if (!studentQuestionnairesData) {
      addErrorMessage("アンケート情報の取得に失敗しているため送信できません");
      return;
    }

    const formattedData = new FormData();

    formattedData.append(
      afterQuestionnairesFormKeys.lessonRequestIdKeys,
      studentQuestionnairesData.lesson_request_id?.toString() || ""
    );
    formattedData.append(
      afterQuestionnairesFormKeys.schoolPrefectureNameKeys,
      studentQuestionnairesData?.school_prefecture_name || ""
    );
    formattedData.append(
      afterQuestionnairesFormKeys.schoolNameKeys,
      studentQuestionnairesData?.school_name || ""
    );

    formattedData.append(
      afterQuestionnairesFormKeys.schoolClassKeys,
      data.schoolClass
    );
    formattedData.append(
      afterQuestionnairesFormKeys.studentIDNumberKeys,
      data.studentIDNumber
    );
    formattedData.append(
      afterQuestionnairesFormKeys.learnWantedToKnowKeys,
      data.learnWantedToKnow
    );
    formattedData.append(
      afterQuestionnairesFormKeys.isUsefulKeys,
      data.isUseful
    );
    formattedData.append(
      afterQuestionnairesFormKeys.wantedToKnowMoreKeys,
      data.wantedToKnowMore
    );
    formattedData.append(
      afterQuestionnairesFormKeys.wantedToTellKeys,
      data.wantedToTell
    );

    Axios.post(CorsProxy + afterQuestionnairesFormKeys.formUrl, formattedData)
      .then(() => {
        setStudentQuestionnairesData(undefined);
        history.push("student_after_questionnaires/complete");
      })
      .catch((error) => {
        console.log(error.response);
        addErrorMessage("アンケートの送信に失敗しました");
      });
  };

  return {
    isLoading,
    submitBeforeQuestionnaireForm,
    submitAfterQuestionnaireForm
  };
};

export default useQuestionnairesApi;
