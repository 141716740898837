import React from "react";
import { useAtomValue } from "jotai";

import useTheme from "@mui/material/styles/useTheme";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import dayjs from "dayjs";

import { detailsAtom } from "features/schools/store/lessonsStore";
import { useDialog } from "hooks/useDialog";

import Button from "components/atoms/mui/Button";
import UrlBox from "components/molecules/UrlBox";
import CommonModal from "components/molecules/CommonDialog";
import Link from "components/atoms/mui/Link";

import { updateLessonFormSchemaType } from "constants/UpdateLessonFormSchemaBase";

import {
  allLessonReportLink,
  classLessonReportLink,
  personalLessonReportLink
} from "features/schools/constants/lessons/lesson_reports_link";

type BeforeLessonBlockProps = {
  updateLesson: (data: updateLessonFormSchemaType) => void;
};

const BeforeLessonBlock: React.FC<BeforeLessonBlockProps> = (props) => {
  const { updateLesson } = props;
  const theme = useTheme();
  const { isOpen, onOpen, onClose } = useDialog();

  const details = useAtomValue(detailsAtom);

  const handleSkipQuestionnaire = () => {
    updateLesson({
      is_skip_lesson_preliminary_report: true
    });
  };

  const handleCompleteQuestionnaire = () => {
    updateLesson({
      lesson_preliminary_report_completed_at: dayjs().format()
    });
  };

  const getBeforeQuestionnaireUrl = () => {
    switch (process.env.REACT_APP_BUILD_ENV) {
      case "development":
        return `http://localhost:8000/lessons/${details?.id}/student_before_questionnaires?unique_id=${details?.unique_id}`;
      case "staging":
        return `https://pool.fukugyo-sensei.net/lessons/${details?.id}/student_before_questionnaires?unique_id=${details?.unique_id}`;
      case "production":
        return `https://fukugyo-sensei.net/lessons/${details?.id}/student_before_questionnaires?unique_id=${details?.unique_id}`;
      default:
        return "";
    }
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: theme.palette.primaryBackground?.main,
          borderRadius: "8px",
          padding: "1.5rem",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "1rem"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: ".5rem"
          }}
        >
          <Typography variant="h2">
            生徒向けに授業前アンケートを実施しましょう。
          </Typography>
          <Typography>
            アンケートを実施することで以下のようなレポートが生成されます。
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: "1rem"
            }}
          >
            <Link href={allLessonReportLink} newTab>
              全体レポート
            </Link>
            <Link href={classLessonReportLink} newTab>
              クラス別レポート
            </Link>
            <Link href={personalLessonReportLink} newTab>
              生徒個別レポート
            </Link>
          </Box>
        </Box>
        <UrlBox url={getBeforeQuestionnaireUrl()} />
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            justifyContent: "flex-end"
          }}
        >
          <Button isLink onClick={onOpen}>
            授業前アンケートを実施しない
          </Button>
          <Button onClick={handleCompleteQuestionnaire}>実施した</Button>
        </Box>
      </Box>
      <CommonModal open={isOpen} onClose={onClose}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            justifyContent: "space-between"
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: ".5rem"
            }}
          >
            <Typography fontWeight="bold" fontSize="1.1rem">
              本当に授業前アンケートをスキップしますか？
            </Typography>
            <Typography>
              授業前アンケートを実施しない場合、授業前レポートが作成されません。
              <br />
              学びレポートの情報が半減してしまうため、効果が大きく下がる可能性があります。
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              justifyContent: "space-between",
              flexDirection: { xs: "column-reverse", sm: "row" }
            }}
          >
            <Button
              variant="outlined"
              color="inherit"
              onClick={onClose}
              sx={{
                flex: 1
              }}
            >
              キャンセル
            </Button>
            <Button onClick={handleSkipQuestionnaire}>
              授業前アンケートをスキップする
            </Button>
          </Box>
        </Box>
      </CommonModal>
    </>
  );
};

export default BeforeLessonBlock;
