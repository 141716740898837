import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useAtom } from "jotai";
import React from "react";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import CommonLoading from "components/atoms/CommonLoading";
import CommonMypageHomeSectionTitle from "components/molecules/CommonMypageHomeSectionTitle";
import LessonItem from "features/schools/components/molecules/mypage/v2/home/LessonItem";
import { schoolLessonsAtom } from "store/schools/mypage/HomeStore";

import LessonIcon from "assets/common/sidebar/selected/lesson.svg";
import { Stack, Paper } from "@mui/material";

interface HomeLessonsProps {
  isLoading: boolean;
}

const HomeLessons: React.FC<HomeLessonsProps> = ({ isLoading }) => {
  const theme = useTheme();
  const [schoolLessons] = useAtom(schoolLessonsAtom);

  return (
    <Stack component="section" gap={2} width="100%">
      <CommonMypageHomeSectionTitle
        Icon={<img src={LessonIcon} alt="授業進行管理" />}
        title="授業進行管理"
        count={schoolLessons?.data_count || 0}
        linkName="すべての授業を見る"
        link="/schools/mypage/lessons"
      />
      <Stack component={Paper} p={2} gap={2} width="100%" sx={{ boxShadow: 0 }}>
        <Box
          sx={{
            display: "flex",
            width: "100%"
          }}
        >
          <Typography fontWeight="bold" sx={{ flex: 1 }}>
            授業
          </Typography>
          <Typography fontWeight="bold">ステータス</Typography>
        </Box>
        <Divider sx={{ width: "100%" }} />
        {!isLoading ? (
          schoolLessons !== null ? (
            schoolLessons.data.map((schoolLesson, index) => (
              <React.Fragment key={index}>
                <LessonItem
                  id={schoolLesson.details.id}
                  exactStartAt={new Date(schoolLesson.details.exact_start_at)}
                  schoolMinutes={schoolLesson.details.school_minutes}
                  teacherName={schoolLesson.teacher.name}
                  teacherImage={schoolLesson.teacher.image.url}
                  teacherRank={schoolLesson.teacher.teacher_rank}
                  step={schoolLesson.details.step}
                />
                <Divider sx={{ width: "100%" }} />
              </React.Fragment>
            ))
          ) : (
            <Typography color={theme.palette.sub.main}>
              授業はありません
            </Typography>
          )
        ) : (
          <CommonLoading />
        )}
      </Stack>
    </Stack>
  );
};

export default HomeLessons;
