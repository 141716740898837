import React from "react";
import { useAtomValue } from "jotai";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useTheme from "@mui/material/styles/useTheme";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { Methods as UpdateLessonMethods } from "api/api/v1/lessons/_lessonId@string";

import { detailsAtom as detailsTeacherAtom } from "features/teachers/stores/lessonsStore";
import { detailsAtom as detailsSchoolAtom } from "features/schools/store/lessonsStore";

import FormSubmitButton from "components/molecules/FormSubmitButton";
import FormTextField from "components/molecules/FormTextField";

import {
  patchLessonPlaceFormSchema,
  patchLessonUrlFormSchema
} from "features/lessons/constants/patchLessonPlaceFormSchema";
import { formatDateTimeStringWithDay } from "utils/date";

type LessonPlaceBlockProps = {
  updateLesson: (
    data: UpdateLessonMethods["patch"]["reqBody"]
  ) => Promise<void>;
};

const LessonPlaceBlock: React.FC<LessonPlaceBlockProps> = (props) => {
  const { updateLesson } = props;
  const theme = useTheme();

  const detailsTeacher = useAtomValue(detailsTeacherAtom);
  const detailsSchool = useAtomValue(detailsSchoolAtom);
  const details = detailsTeacher || detailsSchool;

  const formFieldData = {
    name: details?.is_online ? "lesson_url" : "place",
    label: details?.is_online ? "授業URLを設定" : "授業場所を設定",
    placeholder: details?.is_online
      ? "例）https://meeting.tool/1234567890"
      : "例）東京都〇〇区□□1-1-1"
  };

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(
      details?.is_online ? patchLessonUrlFormSchema : patchLessonPlaceFormSchema
    )
  });

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primaryBackground?.main,
        borderRadius: "8px",
        padding: "1.5rem",
        width: "100%"
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem"
        }}
      >
        <Box>
          <Typography variant="h2">
            {details?.is_online ? "授業URL" : "授業場所"}を設定しましょう
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          marginTop: "1rem"
        }}
      >
        <Box
          sx={{
            border: `1px solid ${theme.palette.border?.main}`,
            borderRadius: "8px",
            backgroundColor: theme.palette.common.white,
            padding: "1rem"
          }}
        >
          <Typography>
            <Typography fontWeight="bold" component="span">
              授業日時：
            </Typography>
            <Typography component="span">
              {details && formatDateTimeStringWithDay(details.exact_start_at)}
            </Typography>
          </Typography>
        </Box>
        <FormProvider {...methods}>
          <Box
            component="form"
            onSubmit={methods.handleSubmit(updateLesson)}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem"
            }}
          >
            <FormTextField
              name={formFieldData.name}
              label={formFieldData.label}
              placeholder={formFieldData.placeholder}
              isRequired
            />
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                justifyContent: "flex-end"
              }}
            >
              <FormSubmitButton
                label={
                  details?.is_online ? "URLを登録する" : "授業場所を登録する"
                }
              />
            </Box>
          </Box>
        </FormProvider>
      </Box>
    </Box>
  );
};

export default LessonPlaceBlock;
