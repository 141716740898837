// 文字列を任意の文字数、行数に制限する関数
const shortSentences = (
  sentences: string,
  customLength?: number,
  customRow?: number
) => {
  const maxLength = customLength || 100;
  const displayRow = customRow || 3;

  // 文字数制限
  if (sentences.length > maxLength) {
    return `${sentences.substring(0, maxLength)}...`;
  }

  // 行数制限
  const lines = sentences.split("\n");
  if (lines.length > displayRow) {
    return `${lines.slice(0, displayRow).join("\n")}...`;
  }

  return sentences;
};

export default shortSentences;
