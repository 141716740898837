import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useAtom } from "jotai";

import {
  schoolTeacherAtom,
  detailsAtom
} from "features/teachers/stores/requestsStore";

import type React from "react";

const SchoolInfoBlock: React.FC = () => {
  const [schoolTeacher] = useAtom(schoolTeacherAtom);
  const [details] = useAtom(detailsAtom);

  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        padding: ".5rem 1.5rem"
      }}
    >
      <Box sx={{ margin: "1rem auto" }}>
        <Typography color={theme.palette.sub.main} fontSize=".8rem">
          [依頼主]
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center"
          }}
        >
          <Avatar
            alt={schoolTeacher?.name}
            src={schoolTeacher?.image.url}
            sx={{
              width: "3.5rem",
              height: "3.5rem",
              marginRight: "1rem"
            }}
          />
          <div>
            <Typography color={theme.palette.sub.main} fontSize=".8rem">
              {schoolTeacher?.school_name}
            </Typography>
            <Typography fontWeight="bold">{schoolTeacher?.name}</Typography>
          </div>
        </Box>
      </Box>
      <Divider />
      {details?.start_at && (
        <Box sx={{ margin: "1rem auto" }}>
          <Typography color={theme.palette.sub.main} fontSize=".8rem">
            [授業実施日]
          </Typography>
          <Typography fontWeight="bold" fontSize="1.1rem">
            {details?.start_at}
          </Typography>
        </Box>
      )}
      <Box sx={{ margin: "1rem auto" }}>
        <Typography>授業テーマ：</Typography>
        <Chip
          label={details?.tag}
          variant="outlined"
          color="default"
          sx={{
            height: "32px",
            borderRadius: "16px",
            margin: ".5rem auto",
            backgroundColor: theme.palette.backgroundColor?.main,
            borderColor: theme.palette.muted?.main,
            color: theme.palette.sub.main
          }}
        />
      </Box>
    </Box>
  );
};

export default SchoolInfoBlock;
