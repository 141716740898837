import React from "react";
import { useAtomValue } from "jotai";

import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";

import { studentQuestionnairesDataAtom } from "features/schools/store/studentQuestionnairesStore";
import LessonInfoItem from "features/schools/components/molecules/mypage/lessons/LessonInfoItem";
import { formatDateTimeStringWithDay } from "utils/date";

const LessonInfoBlock: React.FC = () => {
  const theme = useTheme();

  const studentQuestionnairesData = useAtomValue(studentQuestionnairesDataAtom);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        border: `1px solid ${theme.palette.border?.main}`,
        backgroundColor: theme.palette.common.white,
        borderRadius: "4px",
        padding: "1.5rem"
      }}
    >
      <Typography variant="h2">授業の概要</Typography>
      <LessonInfoItem
        title="学校名"
        value={studentQuestionnairesData?.school_name}
      />
      <LessonInfoItem
        title="授業日時"
        value={
          studentQuestionnairesData?.exact_start_at &&
          formatDateTimeStringWithDay(studentQuestionnairesData.exact_start_at)
        }
      />
      <LessonInfoItem
        title="授業をしてくれる先生"
        value={
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              alignItems: "center"
            }}
          >
            <Avatar
              src={studentQuestionnairesData?.teacher_image?.url}
              alt={`${studentQuestionnairesData?.teacher_name} avatar`}
            />
            <Typography>{studentQuestionnairesData?.teacher_name}</Typography>
          </Box>
        }
      />
    </Box>
  );
};

export default LessonInfoBlock;
