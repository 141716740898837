import useAspidaSWR from "@aspida/swr";
import { useAlertMessages } from "hooks/useAlertMessages";
import useHeaders from "hooks/useHeaders";
import { useAtomValue } from "jotai";
import aspida from "libs/aspida";
import { currentUserAtom } from "store/AuthStore";

const useMyPageApi = () => {
  const headers = useHeaders();
  const { addErrorMessage, addSuccessMessage } = useAlertMessages();
  const currentUser = useAtomValue(currentUserAtom);
  const teacherId = currentUser.id.toString();
  const teacherProfileId = currentUser.teacher_profile_id.toString();

  const { data, mutate } = useAspidaSWR(
    aspida(headers).api.v1.teachers._teacherId_string(teacherId),
    {
      onError: () => {
        addErrorMessage("複業先生情報の取得に失敗しました");
      }
    }
  );

  const handleRejudge = async () => {
    try {
      const res = await aspida(headers)
        .api.v1.teacher_profiles._teacherProfileId(teacherProfileId)
        .judge_request.post({});
      await mutate();
      if (res.status === 200) {
        addSuccessMessage("再審査を依頼しました");
      }
    } catch (error) {
      addErrorMessage("再審査の処理に失敗しました");
    }
  };

  const judgeNg = data?.is_public_judge_ng;
  const isJudging = !judgeNg && !currentUser.is_public;
  const judgeNgReason = data?.public_judge_ng_reason;

  return { judgeNg, isJudging, judgeNgReason, handleRejudge };
};

export default useMyPageApi;
