import React from "react";
import { useAtomValue } from "jotai";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

import FormFullNameField from "components/molecules/FormFullNameField";
import FormTextField from "components/molecules/FormTextField";
import FormFacePhotoField from "components/molecules/FormFacePhotoField";
import FormBirthdayField from "components/molecules/FormBirthdayField";
import FormRadioGroup from "components/molecules/FormRadioGroup";

import MasterSchoolField from "features/schools/components/molecules/profile/MasterSchoolField";

import { mailMagazineOptions } from "constants/selectOptions";
import { knowReasonsData } from "store/SchoolMasterData";

const NewProfileForm: React.FC = () => {
  const knowReasons = useAtomValue(knowReasonsData);

  const shapedKnowReasons = knowReasons.map((knowReason) => ({
    label: knowReason.name,
    value: knowReason.id
  }));

  return (
    <Box component="section" sx={{ padding: "0 0 1rem" }}>
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem"
        }}
      >
        <MasterSchoolField />
        <FormFullNameField label="氏名" isRequired />
        <FormTextField
          name="phone_number"
          label="電話番号（ハイフンなし）"
          isRequired
          supplementalText="授業実施のやり取りの際に、こちらの番号にご連絡がいくことがあります。"
          placeholder="例：01234567890"
        />
        <FormFacePhotoField name="image" label="プロフィール画像" />
        <FormBirthdayField
          name="birthday"
          label="生年月日"
          placeholders={{
            year: "1990",
            month: "1",
            date: "1"
          }}
          supplementalText="本人確認の際に利用するため、一般公開はされません。"
        />
        <FormTextField
          name="manage_grade"
          label="担当学年"
          placeholder="例：1年"
        />
        <FormTextField
          name="manage_subject"
          label="担当教科"
          placeholder="例：1年"
        />
        <FormTextField
          name="school_job"
          label="校務分掌"
          placeholder="例：1年"
        />
        <FormRadioGroup
          name="is_mail_magazine"
          label="メールマガジンの配信"
          options={mailMagazineOptions}
          isRequired
        />
        <FormRadioGroup
          name="know_reason_id"
          label="複業先生を知ったきっかけ"
          options={shapedKnowReasons}
        />
      </Container>
    </Box>
  );
};

export default NewProfileForm;
