import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useAtom } from "jotai";
import React, { useEffect } from "react";

import CommonLoading from "components/atoms/CommonLoading";
import ErrorAlert from "components/atoms/ErrorAlert";
import RequestListItem from "features/teachers/components/molecules/mypage/v2/requests/RequestListItem";
import useGetRequest from "features/teachers/hooks/useGetRequest";
// TODO: checkDeadlineに置き換える
import useLimitCheck from "hooks/useLimitCheck";
import { currentUserAtom } from "store/AuthStore";
import {
  teacherRequestsAtom,
  kindOfTeacherRequestsAtom
} from "store/teachers/mypage/RequestsStore";

const RequestList: React.FC = () => {
  const theme = useTheme();
  const [currentUser] = useAtom(currentUserAtom);
  const [teacherRequests, setTeacherRequests] = useAtom(teacherRequestsAtom);
  const [kindOfTeacherRequests] = useAtom(kindOfTeacherRequestsAtom);
  const { getLimit } = useLimitCheck();

  const { isLoading, isError, setIsError, getRequest } = useGetRequest({
    url: `/api/api/v1/teachers/${currentUser.id}/lesson_requests`
  });

  useEffect(() => {
    const params = {
      status: kindOfTeacherRequests
    };

    getRequest({
      params,
      onSuccess: (data) => {
        setTeacherRequests(data);
      }
    });
  }, [getRequest, kindOfTeacherRequests, setTeacherRequests]);

  return (
    <>
      <ErrorAlert
        isError={isError}
        setIsError={setIsError}
        errorMessage="依頼一覧の取得に失敗しました。"
      />
      <Box
        component="section"
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "1rem"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "1rem",
            padding: "1rem",
            backgroundColor: theme.palette.common.white,
            width: "100%"
          }}
        >
          <Box sx={{ display: { xs: "none", sm: "block" }, width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%"
              }}
            >
              <Typography fontWeight="bold" sx={{ flex: 16 }}>
                依頼元の学校と先生
              </Typography>
              <Typography
                fontWeight="bold"
                align="center"
                sx={{
                  flex: 4,
                  width: "100%"
                }}
              >
                ステータス
              </Typography>
              <Typography
                fontWeight="bold"
                align="center"
                sx={{
                  flex: 3,
                  width: "100%"
                }}
              >
                期限
              </Typography>
            </Box>
            <Divider sx={{ width: "100%" }} />
          </Box>
          {!isLoading ? (
            teacherRequests && teacherRequests.data.length ? (
              teacherRequests.data.map((request, index) => {
                const { limit } = getLimit(
                  String(request.details.accept_dead_at)
                );

                return (
                  <React.Fragment key={index}>
                    <RequestListItem
                      id={request.details.id}
                      schoolName={request.send_user.school_name}
                      schoolTeacherName={request.send_user.name}
                      schoolTeacherImage={request.send_user.image.url}
                      isCertification={request.send_user.is_match_accepted}
                      limit={limit}
                      step={request.details.step}
                    />
                    <Divider sx={{ width: "100%" }} />
                  </React.Fragment>
                );
              })
            ) : (
              <Typography color={theme.palette.sub.main}>
                依頼がありません。
              </Typography>
            )
          ) : (
            <CommonLoading />
          )}
        </Box>
      </Box>
    </>
  );
};

export default RequestList;
