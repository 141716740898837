import { useAtomValue } from "jotai";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";

import RequestInfoBlock from "features/requests/components/molecules/show/RequestInfoBlock";
import ReplyForm from "features/teachers/components/molecules/show/requests/ReplyForm";

import type React from "react";

import useLimit from "hooks/useLimit";

import { detailsAtom } from "features/teachers/stores/requestsStore";

import { replyRequestDataType } from "features/teachers/hooks/request/useShowPageApi";

type RequestJudgmentProps = {
  replyRequest: (data: replyRequestDataType) => Promise<void>;
};

const RequestJudgment: React.FC<RequestJudgmentProps> = (props) => {
  const { replyRequest } = props;
  const details = useAtomValue(detailsAtom);
  const theme = useTheme();

  const accept_dead_at = details?.accept_dead_at ? details.accept_dead_at : "";
  const { limit } = useLimit(new Date(accept_dead_at), 2);

  return (
    <div>
      <Box sx={{ margin: "0 auto 1rem" }}>
        <Typography align="center">
          <Box component="span" sx={{ display: "inline-block" }}>
            授業依頼がありました。
          </Box>
          <Box component="span" sx={{ display: "inline-block" }}>
            承諾・辞退を選択してください。
          </Box>
          <br />
          <Box component="span" sx={{ display: "inline-block" }}>
            依頼承諾期限まであと{limit}日です。
          </Box>
        </Typography>
      </Box>
      <Box
        sx={{
          borderRadius: 4,
          border: `solid 1px ${theme.palette.border?.main}`,
          overflow: "hidden",
          margin: { xs: "1rem auto", md: "1.5rem auto" },
          padding: { xs: "1rem", md: "1.5rem" }
        }}
      >
        <RequestInfoBlock />
      </Box>
      <ReplyForm replyRequest={replyRequest} />
    </div>
  );
};

export default RequestJudgment;
