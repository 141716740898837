// TODO: utils/shortSentencesに置き換える
const useShortSentences = (
  sentences: string,
  customLength?: number,
  customRow?: number
) => {
  const maxLength = customLength || 100;
  const displayRow = customRow || 3;

  if (sentences.length > maxLength) {
    return `${sentences.substr(0, maxLength)}...`;
  }
  const preSectences = sentences.split("\n");

  if (preSectences.length > displayRow) {
    return `${preSectences.splice(0, displayRow).join(",").replace(",", "\n")}...`;
  }
  return sentences;
};

export default useShortSentences;
