import { useEffect, useState } from "react";
import aspida from "libs/aspida";
import useAspidaSWR from "@aspida/swr";
import { useAtom } from "jotai";
import { useHistory } from "react-router";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import useHeaders from "hooks/useHeaders";
import { useAlertMessages } from "hooks/useAlertMessages";

import { Methods as EditPageMethods } from "api/api/v1/school_teacher_profiles/_schoolTeacherProfileId@string/edit";
import { Methods as UpdateMethods } from "api/api/v1/school_teacher_profiles/_schoolTeacherProfileId@string";
import profileEditFormSchema from "features/schools/constants/profileEditFormSchema";
import lodash from "lodash";

import {
  schoolDivisionsData,
  schoolTypesData,
  prefecturesData
} from "store/SchoolMasterData";

import {
  selectedSchoolAtom,
  isExceptionSchoolAtom
} from "store/MasterSchoolStore";

const useEditPageApi = (teacherProfileId: number) => {
  const headers = useHeaders();
  const history = useHistory();
  const { addErrorMessage, addSuccessMessage } = useAlertMessages();

  const [, setSchoolDivisions] = useAtom(schoolDivisionsData);
  const [, setSchoolTypes] = useAtom(schoolTypesData);
  const [, setPrefectures] = useAtom(prefecturesData);

  const [, setSelectedSchool] = useAtom(selectedSchoolAtom);
  const [, setIsExceptionSchool] = useAtom(isExceptionSchoolAtom);

  const [postIsLoading, setPostIsLoading] = useState(false);

  const methods = useForm<UpdateMethods["put"]["reqBody"]>({
    mode: "onChange",
    resolver: yupResolver(profileEditFormSchema)
  });

  const {
    formState: { dirtyFields },
    reset
  } = methods;

  const getProfileEditFormDefaultValue = (
    data: Partial<EditPageMethods["get"]["resBody"]>
  ) => {
    const { details } = data;
    if (!details) return undefined;

    const result: UpdateMethods["put"]["reqBody"] = {
      school_code: details.master_school
        ? details.master_school.school_code
        : undefined,
      school_name: details.master_school ? undefined : details.school_name,
      school_division_id: details.master_school
        ? undefined
        : details.school_division_id,
      school_type_id: details.master_school
        ? undefined
        : details.school_type_id,
      school_prefecture_id: details.master_school
        ? undefined
        : details.school_prefecture_id,
      school_address: details.master_school
        ? undefined
        : details.school_address,
      manage_grade: details.manage_grade,
      manage_subject: details.manage_subject,
      school_job: details.school_job,
      image: details.image.url,
      is_mail_magazine: details.is_mail_magazine
    };

    if (details.master_school) {
      setSelectedSchool(details.master_school);
      setIsExceptionSchool(false);
    } else {
      setIsExceptionSchool(true);
    }

    return result as UpdateMethods["put"]["reqBody"];
  };

  const {
    data,
    mutate,
    isLoading: getIsLoading
  } = useAspidaSWR(
    aspida(headers).api.v1.school_teacher_profiles._schoolTeacherProfileId(
      teacherProfileId.toString()
    ).edit,
    {
      onError: () => {
        addErrorMessage("データの取得に失敗しました");
        history.push("/schools/login");
      }
    }
  );

  const updateProfile = async (params: UpdateMethods["put"]["reqBody"]) => {
    setPostIsLoading(true);

    // dirtyフィールドのみを送信する
    const dirtyParams = lodash.pick(params, Object.keys(dirtyFields));
    // dirtyParamsにimageが含まれている場合はimage_file_nameを追加
    if (dirtyFields.image) {
      dirtyParams.image_file_name = "profile";
    }

    try {
      const response = await aspida(headers)
        .api.v1.school_teacher_profiles._schoolTeacherProfileId(
          teacherProfileId.toString()
        )
        .put({
          body: dirtyParams
        });
      if (response.status === 200) {
        mutate();
        addSuccessMessage("プロフィールを更新しました");
      } else {
        addErrorMessage("プロフィールの更新に失敗しました");
      }
    } catch (error) {
      addErrorMessage("プロフィールの更新に失敗しました");
    } finally {
      setPostIsLoading(false);
    }
  };

  const handleSubmit = methods.handleSubmit((data) => {
    updateProfile(data);
  });

  useEffect(() => {
    if (data) {
      setPrefectures(data.prefectures);
      setSchoolTypes(data.school_type);
      setSchoolDivisions(data.school_division);

      const profilePutDefaultValues = getProfileEditFormDefaultValue(data);
      reset(profilePutDefaultValues);
    }
  }, [data]);

  return { methods, data, handleSubmit, getIsLoading, postIsLoading };
};

export default useEditPageApi;
