import { atom } from "jotai";

import type { schoolRequestResponseType } from "types/lessonRecruitmentsTypes";

export type kindOfSchoolLessonsType =
  | ""
  | "lesson_end"
  | "preparation"
  | "canceled";

export const schoolLessonsAtom = atom<schoolRequestResponseType | null>(null);

export const kindOfSchoolLessonsAtom = atom<kindOfSchoolLessonsType>("");
