import React from "react";
import { useFormContext, Controller } from "react-hook-form";

import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";

import FormLabel from "components/atoms/FormLabel";
import DatePicker from "components/atoms/fields/DatePicker";
import Select from "components/atoms/fields/Select";

import get from "lodash/get";
import { FormControl, Stack, useMediaQuery } from "@mui/material";
import {
  hoursOptions as hoursOptionsDefault,
  minutesOptions as minutesOptionsDefault
} from "constants/selectOptions";

type FormDatetimeProps = {
  label?: string;
  nameFrom: string;
  nameTo: string;
  supplementalText?: string;
  isRequired?: boolean;
  minDate?: Date;
  noLabel?: boolean;
  hoursOptions?: {
    label: string;
    value: number;
  }[];
  minutesOptions?: {
    label: string;
    value: number;
  }[];
  monitoredTargets?: string[];
};

const FormDatetimeRange: React.FC<FormDatetimeProps> = ({
  label,
  nameFrom,
  nameTo,
  supplementalText,
  isRequired,
  noLabel,
  minDate,
  hoursOptions = hoursOptionsDefault,
  minutesOptions = minutesOptionsDefault,
  monitoredTargets
}) => {
  const {
    control,
    trigger,
    formState: { errors },
    setValue,
    getValues
  } = useFormContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  // FIXME: エラーの判定が表示されない、もしくは行われていない
  const isError = !!get(errors, nameFrom) && !!get(errors, nameTo);
  const errorMessage =
    get(errors, nameFrom)?.message || get(errors, nameTo)?.message;

  const handleDateTimeChange = (
    // FIXME: editFieldNameの引数の型はnameFromとnameToの値にしたい
    editFieldName: string,
    date: Date | null,
    field?: "hours" | "minutes",
    value?: number
  ) => {
    if (!date) return;
    // FIXME: 毎回Dateオブジェクトを生成しているので、最適化が必要
    const currentValueBase =
      editFieldName === nameFrom ? getValues(nameFrom) : getValues(nameTo);
    const currentNameFromValue = new Date(getValues(nameFrom));
    const currentNameToValue = new Date(getValues(nameTo));
    const currentValue = new Date(currentValueBase || new Date());

    if (field && value !== undefined) {
      currentValue[field === "hours" ? "setHours" : "setMinutes"](value);
      currentValue.setSeconds(0);
      setValue(editFieldName, currentValue.toString(), { shouldDirty: true });
    } else {
      currentNameFromValue.setFullYear(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );
      currentNameToValue.setFullYear(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );

      currentValue.setSeconds(0);
      setValue(nameFrom, currentNameFromValue.toString(), {
        shouldDirty: true
      });
      setValue(nameTo, currentNameToValue.toString(), { shouldDirty: true });
    }

    trigger(editFieldName);

    // MEMO: 各FormFieldコンポーネントに仕込んでもいいかも
    if (monitoredTargets) {
      monitoredTargets.forEach((target) => {
        trigger(target);
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: ".5rem"
      }}
    >
      {label &&
        (!noLabel ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "0.25rem"
            }}
          >
            <FormLabel labelName={label} isRequired={isRequired} />
            {supplementalText && (
              <Typography
                variant="caption"
                sx={{ color: theme.palette.sub.main }}
              >
                {supplementalText}
              </Typography>
            )}
          </Box>
        ) : (
          <Typography fontWeight="bold">{label}</Typography>
        ))}

      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          gap: "1rem",
          alignItems: "center"
        }}
      >
        <Controller
          name={nameFrom}
          control={control}
          render={({ field }) => (
            <Stack
              direction={isMobile ? "column" : "row"}
              gap={1}
              sx={{ width: "100%" }}
            >
              <DatePicker
                value={new Date(field.value)}
                minDate={minDate}
                onChange={(date) => handleDateTimeChange(nameFrom, date)}
                error={isError}
                width="100%"
              />
              <FormControl sx={{ minWidth: 96 }}>
                <Select
                  value={new Date(field.value).getHours()}
                  options={hoursOptions}
                  onChange={(e) =>
                    handleDateTimeChange(
                      nameFrom,
                      field.value,
                      "hours",
                      e.target.value as number
                    )
                  }
                  error={isError}
                  width="100%"
                />
              </FormControl>
              <FormControl sx={{ minWidth: 96 }}>
                <Select
                  value={new Date(field.value).getMinutes()}
                  options={minutesOptions}
                  onChange={(e) =>
                    handleDateTimeChange(
                      nameFrom,
                      field.value,
                      "minutes",
                      e.target.value as number
                    )
                  }
                  error={isError}
                  width="100%"
                />
              </FormControl>
            </Stack>
          )}
        />
        <Typography>{isMobile ? "|" : "-"}</Typography>
        <Controller
          name={nameTo}
          control={control}
          render={({ field }) => (
            <Stack
              direction={isMobile ? "column" : "row"}
              gap={1}
              sx={{ width: isMobile ? "100%" : undefined }}
            >
              <FormControl sx={{ minWidth: 96 }} fullWidth>
                <Select
                  value={new Date(field.value).getHours()}
                  options={hoursOptions}
                  onChange={(e) =>
                    handleDateTimeChange(
                      nameTo,
                      field.value,
                      "hours",
                      e.target.value as number
                    )
                  }
                  error={isError}
                  width="100%"
                />
              </FormControl>
              <FormControl sx={{ minWidth: 96 }} fullWidth>
                <Select
                  value={new Date(field.value).getMinutes()}
                  options={minutesOptions}
                  onChange={(e) =>
                    handleDateTimeChange(
                      nameTo,
                      field.value,
                      "minutes",
                      e.target.value as number
                    )
                  }
                  error={isError}
                  width="100%"
                />
              </FormControl>
            </Stack>
          )}
        />
      </Box>
      {isError && (
        <FormHelperText sx={{ color: theme.palette.error.main }}>
          {errorMessage?.toString()}
        </FormHelperText>
      )}
    </Box>
  );
};

export default FormDatetimeRange;
