import { useState, useEffect } from "react";
import { useAtomValue } from "jotai";
import dayjs from "dayjs";

import { lessonMeetingPossibleDatetimesAtom } from "features/teachers/stores/lessonsStore";

import { minutesOptions } from "constants/selectOptions";
import { meetingScheduleHoursOptions } from "features/schools/constants/lessons/meetingScheduleSelectOptions";

type optionsType = Array<{
  label: string;
  value: number;
}>;

const useSelectDatetimeOptions = (selectedMeetingStartAt?: string) => {
  const lessonMeetingPossibleDatetimes = useAtomValue(
    lessonMeetingPossibleDatetimesAtom
  );
  const [customHoursOptions, setCustomHoursOptions] = useState(
    meetingScheduleHoursOptions
  );
  const [customMinutesOptions, setCustomMinutesOptions] =
    useState(minutesOptions);

  useEffect(() => {
    if (selectedMeetingStartAt) {
      const selectableDates = lessonMeetingPossibleDatetimes?.filter(
        (datetime) =>
          dayjs(selectedMeetingStartAt).isSame(
            dayjs(datetime.possible_datetime_from),
            "day"
          )
      );

      if (selectableDates) {
        const newOptions: optionsType = [];
        const selectedMeetingStartAtDay = dayjs(selectedMeetingStartAt);

        const newHourOptions = () => {
          selectableDates.forEach((selectableDate) => {
            let options = meetingScheduleHoursOptions;
            const possibleDatetimeFrom = dayjs(
              selectableDate.possible_datetime_from
            );
            const possibleDatetimeTo = dayjs(
              selectableDate.possible_datetime_to
            );

            if (possibleDatetimeFrom.isSame(selectedMeetingStartAtDay, "day")) {
              options = options.filter(
                (option) => option.value >= possibleDatetimeFrom.hour()
              );
            }

            if (possibleDatetimeTo.isSame(selectedMeetingStartAtDay, "day")) {
              options = options.filter(
                (option) => option.value <= possibleDatetimeTo.hour() - 1
              );
            }

            newOptions.push(...options);
          });

          // newOptions の重複を削除し、昇順に並び替える
          return newOptions
            .filter((option, index, self) => self.indexOf(option) === index)
            .sort((a, b) => a.value - b.value);
        };

        const newMinuteOptions = () => {
          const newOptions: optionsType = [];

          selectableDates.forEach((selectableDate) => {
            let options = minutesOptions;
            const possibleDatetimeFrom = dayjs(
              selectableDate.possible_datetime_from
            );
            const possibleDatetimeTo = dayjs(
              selectableDate.possible_datetime_to
            );

            if (
              possibleDatetimeFrom.isSame(selectedMeetingStartAtDay, "day") &&
              possibleDatetimeFrom.isSame(selectedMeetingStartAtDay, "hour")
            ) {
              options = options.filter(
                (option) => option.value >= possibleDatetimeFrom.minute()
              );
              newOptions.push(...options);
            }

            if (
              possibleDatetimeTo.isSame(selectedMeetingStartAtDay, "day") &&
              possibleDatetimeTo.isSame(
                selectedMeetingStartAtDay.add(1, "hours"),
                "hour"
              )
            ) {
              options = options.filter(
                (option) => option.value <= possibleDatetimeTo.minute()
              );
              newOptions.push(...options);
            }
          });

          if (newOptions.length === 0) {
            newOptions.push(...minutesOptions);
          }

          return newOptions;
        };

        setCustomHoursOptions(newHourOptions());
        setCustomMinutesOptions(newMinuteOptions());
      }
    }
  }, [lessonMeetingPossibleDatetimes, selectedMeetingStartAt]);

  return { customHoursOptions, customMinutesOptions };
};

export default useSelectDatetimeOptions;
