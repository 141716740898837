import { useAtomValue } from "jotai";
import { useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

import RequestInfoItem from "features/requests/components/atoms/show/RequestInfoItem";

// MEMO: moleculesだが依頼情報の表示を機能として持つため、例外的にatomと紐付けて再利用
import { detailsAtom as detailsTeacherAtom } from "features/teachers/stores/requestsStore";
import { detailsAtom as detailsSchoolAtom } from "features/schools/store/requestsStore";

const RequestBlock: React.FC = () => {
  const detailsTeacher = useAtomValue(detailsTeacherAtom);
  const detailsSchool = useAtomValue(detailsSchoolAtom);
  const details = detailsTeacher || detailsSchool;
  const theme = useTheme();
  const underSm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container spacing={underSm ? 1 : 2}>
      <Grid item xs={12}>
        <Typography variant="h3">依頼内容</Typography>
      </Grid>
      <RequestInfoItem
        title="学年・生徒数"
        value={`${details?.school_grade}（${details?.student_number}人）`}
      />
      <RequestInfoItem
        title="授業時間"
        value={`${details?.school_minutes}分`}
      />
      {details?.start_at && (
        <RequestInfoItem title="希望実施時期" value={details?.start_at} />
      )}
      <RequestInfoItem
        title="実施方法"
        value={details?.is_online ? "オンライン" : "対面授業"}
      />
      {details?.is_online || (
        <RequestInfoItem title="交通費" value={details?.travel_cost || ""} />
      )}
      <RequestInfoItem title="授業テーマ" value={details?.tag || ""} />
      <RequestInfoItem
        title="話してほしいこと"
        value={details?.lesson_content || ""}
      />
      {details?.student_condition !== "" && (
        <RequestInfoItem
          title="生徒の今の様子"
          value={details?.student_condition || ""}
        />
      )}
      {details?.issue !== "" && (
        <RequestInfoItem
          title="今感じている課題"
          value={details?.issue || ""}
        />
      )}
      {details?.reward !== undefined && (
        <RequestInfoItem
          title="報酬"
          value={
            details.reward > 0 ? (
              <Typography sx={{ whiteSpace: "pre-wrap" }}>
                ￥{details.reward.toLocaleString()}(税抜)
              </Typography>
            ) : (
              <Typography sx={{ whiteSpace: "pre-wrap" }}>
                依頼時点で報酬なしの設定だったため報酬は、0円となっております。
                <br />
                報酬ありに変更する場合は
                <RouterLink to="/teachers/mypage/settings?accordion=basic">
                  こちら
                </RouterLink>
                からお願いします。
              </Typography>
            )
          }
        />
      )}
    </Grid>
  );
};

export default RequestBlock;
