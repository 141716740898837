import React from "react";

import useTheme from "@mui/material/styles/useTheme";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const WaitingForMeetingSuggetionBlock: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primaryBackground?.main,
        borderRadius: "8px",
        padding: "1.5rem",
        width: "100%"
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem"
        }}
      >
        <Box>
          <Typography variant="h2">学校との打ち合わせを行いましょう</Typography>
          <Typography>
            学校からの打ち合わせ日程の提案をお待ちください。
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default WaitingForMeetingSuggetionBlock;
