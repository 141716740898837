import dayjs from "dayjs";

// 時間関連のユーテリティ関数
export const tommorowNoon = () => {
  const now = new Date();
  now.setDate(now.getDate() + 1);
  now.setHours(12, 0, 0, 0);
  return now;
};

// 2024年9月1日
export const formatDateString = (date: Date | string) =>
  dayjs(date).format("YYYY年M月D日");

// 2024年9月1日 12時00分
export const formatDateTimeString = (date: Date | string) =>
  dayjs(date).format("YYYY年M月D日 HH時mm分");

// 2024年9月1日（火）
export const formatDateStringWithDay = (date: Date | string) =>
  dayjs(date).locale("ja").format("YYYY年M月D日（ddd）");

// 2024年9月1日（火）12時00分
export const formatDateTimeStringWithDay = (date: Date | string) =>
  dayjs(date).locale("ja").format("YYYY年M月D日（ddd）HH時mm分");

// 返答期限を計算する関数
export const checkDeadline = (acceptDeadAt: string) => {
  const today = dayjs();
  const deadline = dayjs(acceptDeadAt);

  // 今日と受け取り期限の差を計算
  const limit = deadline.diff(today, "day") + 1;

  // 期限が過ぎているかどうか
  const dead = limit < 0;

  return { limit, dead };
};
