import Axios from "axios";
import { useAtom, useAtomValue } from "jotai";
import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  accessTokenAtom,
  clientAtom,
  uidAtom,
  loginAgainModalAtom
} from "store/AuthStore";

import type { AxiosError } from "axios";
import useTeacherAuthApi from "./useTeacherAuthApi";

type useGetProps = {
  url: string;
};

type doFetchProps = {
  params?: object;
  onSuccess?: (data?: any) => void;
  onError?: (error: AxiosError) => void;
};

const useGetRequest = ({ url }: useGetProps) => {
  const accessToken = useAtomValue(accessTokenAtom);
  const client = useAtomValue(clientAtom);
  const uid = useAtomValue(uidAtom);
  const [, setLoginAgainModal] = useAtom(loginAgainModalAtom);
  const history = useHistory();
  const { logout } = useTeacherAuthApi();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState<any>();

  const getRequest = useCallback(
    async ({ params, onSuccess, onError }: doFetchProps) => {
      const shapingParams = params || {};
      await setIsLoading(true);
      const res = await Axios.get(url, {
        params: shapingParams,
        headers: {
          "access-token": accessToken,
          uid,
          client
        }
      })
        .then((response) => {
          onSuccess && onSuccess(response.data);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            logout();
            setLoginAgainModal(true);
            history.push("/teachers/login");
          }

          setIsError(true);
          setError(error);
          onError && onError(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
      return res;
    },
    [history, setLoginAgainModal]
  );

  return { getRequest, isLoading, isError, error, setIsError };
};

export default useGetRequest;
