import Avatar from "@mui/material/Avatar";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import SettingsIcon from "@mui/icons-material/Settings";
import { useAtom } from "jotai";
import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { currentUserAtom } from "store/AuthStore";

import useTeacherAuthApi from "features/teachers/hooks/useTeacherAuthApi";
import LogoutIcon from "../atoms/LogoutIcon";

const HeaderTeacherContents: React.FC = () => {
  const history = useHistory();
  const { logout } = useTeacherAuthApi();
  const [currentUser] = useAtom(currentUserAtom);
  const [open, setOpen] = useState(false);
  const prevOpen = useRef(open);
  const anchorRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleToMypage = () => {
    history.push("/teachers/mypage");
  };

  const handleToSettings = () => {
    history.push("/teachers/mypage/settings");
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Avatar
        alt="user avatar"
        src={currentUser.image.url}
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        sx={{
          width: { xs: 30, sm: 40 },
          height: { xs: 30, sm: 40 },
          cursor: "pointer"
        }}
      />
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow">
                  <MenuItem onClick={handleToMypage}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        margin: `${theme.spacing(1)} 0`
                      }}
                    >
                      <Avatar
                        alt="user avatar"
                        src={currentUser.image.url}
                        sx={{
                          width: { xs: 30, sm: 40 },
                          height: { xs: 30, sm: 40 },
                          cursor: "pointer"
                        }}
                      />
                      <Box sx={{ margin: `0 ${theme.spacing(1)}` }}>
                        <Typography fontWeight="bold" lineHeight={1.5}>
                          {currentUser.name}
                        </Typography>
                        <Typography
                          fontSize=".8rem"
                          color={theme.palette.sub.main}
                          lineHeight={1.2}
                        >
                          マイページ
                        </Typography>
                      </Box>
                    </Box>
                  </MenuItem>
                  <MenuItem onClick={handleToSettings}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem"
                      }}
                    >
                      <SettingsIcon />
                      <Typography>アカウント設定</Typography>
                    </Box>
                  </MenuItem>
                  <MenuItem onClick={logout}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem"
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: "auto" }}>
                        <SvgIcon>
                          <LogoutIcon />
                        </SvgIcon>
                      </ListItemIcon>
                      <Typography>ログアウト</Typography>
                    </Box>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default HeaderTeacherContents;
