import React from "react";
import { useHistory } from "react-router-dom";

import Typography from "@mui/material/Typography";
import ArrowBack from "@mui/icons-material/ArrowBackIos";
import useTheme from "@mui/material/styles/useTheme";
import Button from "components/atoms/mui/Button";

const GoBackButton: React.FC = () => {
  const history = useHistory();
  const theme = useTheme();

  return (
    <Button
      variant="text"
      color="secondary"
      size="small"
      startIcon={
        <ArrowBack
          sx={{
            color: theme.palette.link?.main,
            height: "1rem",
            width: "1rem"
          }}
        />
      }
      onClick={history.goBack}
    >
      <Typography
        color={theme.palette.link?.main}
        fontSize=".8rem"
        sx={{
          textDecoration: "underline"
        }}
      >
        前の画面へ戻る
      </Typography>
    </Button>
  );
};

export default GoBackButton;
