import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useFieldArray, useFormContext } from "react-hook-form";
import dayjs from "dayjs";

import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import type React from "react";

import FormDatetime from "components/molecules/FormDatetime";

import { FormHelperText } from "@mui/material";

const PossibleDateForm: React.FC = () => {
  const theme = useTheme();
  const {
    formState: { errors }
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    name: "possible_dates",
    rules: {
      required: true,
      maxLength: 3
    }
  });

  const twoweeksLater = dayjs().add(14, "day").toDate();

  return (
    <section>
      <Card elevation={0}>
        <CardContent
          sx={{
            padding: "18px",
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem"
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem"
            }}
          >
            <Typography fontWeight="bold">
              授業日時をご入力ください。
            </Typography>
            <Typography color={theme.palette.sub.main} fontSize="0.9rem">
              <Typography component="span" sx={{ display: "inline-block" }}>
                日付の違う複数の候補をご入力いただくと、
              </Typography>
              <Typography component="span" sx={{ display: "inline-block" }}>
                マッチしやすくなります。
              </Typography>
              <br />
              <Typography component="span" sx={{ display: "inline-block" }}>
                日程が決まっていない場合、
              </Typography>
              <Typography component="span" sx={{ display: "inline-block" }}>
                おおよその日時を入力してください。
              </Typography>
              <br />
              <Typography component="span" sx={{ display: "inline-block" }}>
                授業日時は、必要に応じて
              </Typography>
              <Typography component="span" sx={{ display: "inline-block" }}>
                後からでも変更できます。
              </Typography>
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1.5rem"
            }}
          >
            {fields.map((field, index) => (
              <FormDatetime
                {...field}
                key={field.id}
                label={`第${index + 1}希望`}
                name={`possible_dates[${index}]`}
                supplementalText="授業日時は、必要に応じて後からでも変更できます。"
                isRequired={index === 0}
                minDate={twoweeksLater}
                monitoredTargets={["accept_dead_at"]}
              />
            ))}
            {errors.possibleDates && (
              <FormHelperText error>
                {errors.possibleDates.message?.toString()}
              </FormHelperText>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "1rem"
            }}
          >
            {fields.length < 3 && (
              <Button
                variant="text"
                color="secondary"
                onClick={() => {
                  append(twoweeksLater);
                }}
                sx={{
                  // MEMO: 別途theme.tsで定義した色を指定したほうがいいかも
                  color: "#0093C4",
                  fontWeight: 600,
                  padding: "0.25rem 1rem",
                  "&:hover": {
                    backgroundColor: "rgba(0,147,196,0.1)"
                  }
                }}
              >
                候補日時を追加する
              </Button>
            )}
            {fields.length > 1 && (
              <Button
                variant="text"
                onClick={() => remove(fields.length - 1)}
                sx={{
                  // MEMO: 別途theme.tsで定義した色を指定したほうがいいかも
                  color: "#EE1D00",
                  fontWeight: 600,
                  padding: "0.25rem 1rem",
                  "&:hover": {
                    backgroundColor: "rgba(238,29,0,0.1)"
                  }
                }}
              >
                候補日時を削除する
              </Button>
            )}
          </Box>
        </CardContent>
      </Card>
    </section>
  );
};

export default PossibleDateForm;
