import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useMediaQuery } from "@mui/material";
import Container from "@mui/material/Container";

import Meta from "components/organisms/Meta";
import Sidebar from "features/teachers/components/organisms/common/Sidebar";
import Home from "features/teachers/components/templates/mypage/Home";
import CommonLayout from "components/layouts/CommonLayout";

import type { Theme } from "@mui/material/styles";
import type React from "react";
import AnnouncementDialog from "components/organisms/AnnouncementDialog";
import useAnnouncement from "features/teachers/hooks/useAnnouncement";
import useMyPageApi from "features/teachers/hooks/mypage/useMyPageApi";
import JudgeConfirmationAlert from "../../molecules/show/JudgeConfirmationAlert";
import JudgeNgAlert from "../../molecules/show/JudgeNgAlert";

const Mypage: React.FC = () => {
  const theme = useTheme();
  const { announcementDialogOpen, announcements, confirmAnnouncement } =
    useAnnouncement();
  const { isJudging, judgeNg, judgeNgReason, handleRejudge } = useMyPageApi();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <CommonLayout>
      <Meta title="複業先生｜マイページ" />
      <Box
        sx={{
          backgroundColor: theme.palette.backgroundColor?.main,
          minHeight: { xs: "calc(100vh - 56px)", sm: "calc(100vh - 64px)" }
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: "flex",
            gap: "1.5rem",
            padding: "1.5rem"
          }}
        >
          {!isMobile && <Sidebar />}
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "1.5rem"
            }}
          >
            {isJudging && <JudgeConfirmationAlert />}
            {judgeNg && (
              <JudgeNgAlert
                handlePostRequest={handleRejudge}
                reason={judgeNgReason}
              />
            )}
            <Home />
          </Box>
        </Container>
      </Box>
      <AnnouncementDialog
        isOpen={announcementDialogOpen}
        announcements={announcements}
        confirmAnnouncement={confirmAnnouncement}
      />
    </CommonLayout>
  );
};

export default Mypage;
