import aspida from "libs/aspida";
import useAspidaSWR from "@aspida/swr";
import { useAtom } from "jotai";

import { currentUserAtom } from "store/AuthStore";

import useHeaders from "hooks/useHeaders";
import { useAlertMessages } from "hooks/useAlertMessages";

import {
  detailsAtom,
  schoolTeacherAtom,
  supporterAtom,
  possibleDatesAtom
} from "features/teachers/stores/requestsStore";

import { Methods } from "api/api/v1/lesson_requests/_lessonRequestId@string";

export type replyRequestDataType = Methods["put"]["reqBody"];

const useShowPageApi = (lessonRequestId: string) => {
  const headers = useHeaders();
  const { addErrorMessage } = useAlertMessages();

  const [currentUser] = useAtom(currentUserAtom);
  const [, setDetails] = useAtom(detailsAtom);
  const [, setSchoolTeacher] = useAtom(schoolTeacherAtom);
  const [, setSupporter] = useAtom(supporterAtom);
  const [, setPossibleDates] = useAtom(possibleDatesAtom);

  const { isLoading: getRequestIsLoading, mutate: mutateRequestData } =
    useAspidaSWR(
      // eslint-disable-next-line no-underscore-dangle
      aspida(headers)
        .api.v1.teachers._teacherId_string(currentUser.id.toString())
        .lesson_requests._lessonRequestId(lessonRequestId),
      {
        onSuccess: (data) => {
          setDetails(data.details);
          setSchoolTeacher(data.send_user);
          setSupporter(data.supporter);
          setPossibleDates(data.possible_dates);
        }
      }
    );

  const replyRequest = async (data: Methods["put"]["reqBody"]) => {
    try {
      await aspida(headers)
        .api.v1.lesson_requests._lessonRequestId_string(lessonRequestId)
        .put({ body: data });

      mutateRequestData();
    } catch (error) {
      addErrorMessage("エラーが発生しました。");
    }
  };

  return {
    getRequestIsLoading,
    replyRequest
  };
};

export default useShowPageApi;
