import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import { useAtom } from "jotai";
import React from "react";
import { useParams } from "react-router-dom";

import CommonLayout from "components/layouts/CommonLayout";
import Meta from "components/organisms/Meta";
import RecruitmentInfo from "features/schools/components/organisms/recruitments/show/RecruitmentInfo";
import CandidatesList from "features/schools/components/organisms/recruitments/show/CandidatesList";
import SelectCandidate from "features/schools/components/templates/recruitments/show/SelectCandidate";
import TreatedAsCanceled from "features/schools/components/templates/recruitments/show/TreatedAsCanceled";
import Accepted from "features/schools/components/templates/recruitments/show/Accepted";
import Closed from "features/schools/components/templates/recruitments/show/Closed";

import { recruitmentAtom } from "store/schools/recruitments/ShowStore";

import useShowPageApi from "features/schools/hooks/recruitments/useShowPageApi";
import { FormProvider } from "react-hook-form";
import EarlyCloseBlock from "../../organisms/recruitments/show/EarlyCloseBlock";

type paramsTypes = {
  id: string;
};

const Show: React.FC = () => {
  const theme = useTheme();
  const { id } = useParams<paramsTypes>();
  const [recruitment] = useAtom(recruitmentAtom);

  const {
    isLoading,
    selectCandidateForm,
    selectCandidate,
    earlyClose,
    cancelRecruitment
  } = useShowPageApi(id);

  const getStepContent = () => {
    switch (recruitment?.step) {
      case "candidate_select":
        return (
          <FormProvider {...selectCandidateForm}>
            <form onSubmit={selectCandidateForm.handleSubmit(selectCandidate)}>
              <SelectCandidate />
            </form>
          </FormProvider>
        );
      case "now_recruting":
        return (
          <EarlyCloseBlock
            earlyClose={earlyClose}
            cancelRecruitment={cancelRecruitment}
          />
        );
      case "early_accepted":
      case "accepted":
        return <Accepted />;
      case "no_candidate":
        return <TreatedAsCanceled />;
      case "closed":
      case "early_closed":
        return <Closed />;
      default:
        return null;
    }
  };

  return (
    <CommonLayout isLoading={isLoading}>
      <Meta
        title="複業先生｜募集詳細"
        description="複業先生の募集詳細ページです。"
      />
      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1.5rem",
          padding: "1.5rem",
          width: "100%"
        }}
      >
        <RecruitmentInfo />
      </Container>
      <Box
        sx={{
          backgroundColor: theme.palette.backgroundColor?.main
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
            padding: "1.5rem",
            width: "100%"
          }}
        >
          {getStepContent()}
          <CandidatesList />
        </Container>
      </Box>
    </CommonLayout>
  );
};

export default Show;
