import * as React from "react";
import { TextField } from "@mui/material";
import {
  LocalizationProvider,
  DatePicker as MuiDatePicker
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ja } from "date-fns/locale";

export interface DatePickerProps {
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
  onChange: (date: Date | null) => void;
  value: Date | null;
  width?: string | number;
  minDate?: Date;
  allowedDates?: Date[];
}

const DatePicker: React.FC<DatePickerProps> = ({
  error,
  helperText,
  disabled,
  onChange,
  value,
  width = "350px",
  minDate,
  allowedDates
}) => {
  const shouldDisableDate = (date: Date) => {
    if (!allowedDates) return false;
    // dateがallowedDatesに含まれているかを確認
    return !allowedDates?.some(
      (allowedDate) =>
        date.getFullYear() === allowedDate.getFullYear() &&
        date.getMonth() === allowedDate.getMonth() &&
        date.getDate() === allowedDate.getDate()
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
      <MuiDatePicker
        value={value}
        onChange={onChange}
        disabled={disabled}
        minDate={minDate}
        shouldDisableDate={allowedDates ? shouldDisableDate : undefined}
        renderInput={(params) => (
          <TextField
            {...params}
            error={error}
            helperText={helperText}
            disabled={disabled}
            inputProps={{
              ...params.inputProps,
              placeholder: "年/月/日"
            }}
            sx={{ width }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
