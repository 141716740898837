import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useAtom } from "jotai";
import React, { useEffect } from "react";

import CommonLoading from "components/atoms/CommonLoading";
import ErrorAlert from "components/atoms/ErrorAlert";
import CommonMypageHomeSectionTitle from "components/molecules/CommonMypageHomeSectionTitle";
import LessonItem from "features/teachers/components/molecules/mypage/v2/home/LessonItem";
import useGetRequest from "features/teachers/hooks/useGetRequest";
import { currentUserAtom } from "store/AuthStore";
import { teacherLessonsAtom } from "store/teachers/mypage/HomeStore";

import NoContentItem from "features/teachers/components/molecules/mypage/v2/home/NoContentItem";

import LessonIcon from "assets/common/sidebar/unselected/lesson.svg";

const Lessons: React.FC = () => {
  const theme = useTheme();
  const [currentUser] = useAtom(currentUserAtom);
  const [teacherLessons, setTeacherLessons] = useAtom(teacherLessonsAtom);

  const { isLoading, isError, setIsError, getRequest } = useGetRequest({
    url: `/api/api/v1/teachers/${currentUser.id}/lessons`
  });

  useEffect(() => {
    const params = {
      limit: 3
    };

    getRequest({
      params,
      onSuccess: (data) => {
        setTeacherLessons(data);
      }
    });
  }, [getRequest, setTeacherLessons]);

  return (
    <>
      <ErrorAlert
        isError={isError}
        setIsError={setIsError}
        errorMessage="授業の取得に失敗しました。"
      />
      <Box
        component="section"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          width: "100%"
        }}
      >
        <CommonMypageHomeSectionTitle
          Icon={<img src={LessonIcon} alt="授業進行管理" />}
          title="授業進行管理"
          count={teacherLessons?.data_count || 0}
          linkName="すべての授業を見る"
          link="/teachers/mypage/lessons"
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "1rem",
            padding: "1rem",
            backgroundColor: theme.palette.common.white,
            width: "100%"
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%"
            }}
          >
            <Typography fontWeight="bold" sx={{ flex: 1 }}>
              授業
            </Typography>
            <Typography fontWeight="bold">ステータス</Typography>
          </Box>
          <Divider sx={{ width: "100%" }} />
          {!isLoading ? (
            teacherLessons && teacherLessons.data.length > 0 ? (
              teacherLessons.data.map((schoolLesson, index) => (
                <React.Fragment key={index}>
                  <LessonItem
                    id={schoolLesson.details.id}
                    exactStartAt={new Date(schoolLesson.details.exact_start_at)}
                    schoolMinutes={schoolLesson.details.school_minutes}
                    schoolName={schoolLesson.send_user.school_name}
                    schoolImage={schoolLesson.send_user.image.url}
                    isCertification={schoolLesson.send_user.is_match_accepted}
                    step={schoolLesson.details.step}
                  />
                  <Divider sx={{ width: "100%" }} />
                </React.Fragment>
              ))
            ) : (
              <NoContentItem
                title="まだ成立した授業はありません"
                lead="授業が成立すると、情報が表示されます"
              />
            )
          ) : (
            <CommonLoading />
          )}
        </Box>
      </Box>
    </>
  );
};

export default Lessons;
