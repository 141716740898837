import React from "react";

import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";

import FormSelect from "components/molecules/FormSelect";
import FormRadioGroup from "components/molecules/FormRadioGroup";
import FormTextArea from "components/molecules/FormTextArea";
import FormSubmitButton from "components/molecules/FormSubmitButton";

import afterQuestionnairesFormSchema, {
  afterQuestionnairesFormSchemaType
} from "features/schools/constants/lessons/questionnaires/afterQuestionnairesFormSchema";
import {
  schoolClassesOptions,
  studentIDNumberOptions,
  learnWantedToKnowOptions,
  isUsefulOptions
} from "features/schools/constants/lessons/questionnaires/questionnairesOptions";

type BeforeQuestionnairesFormProps = {
  submitForm: (data: afterQuestionnairesFormSchemaType) => Promise<void>;
};

const BeforeQuestionnairesForm: React.FC<BeforeQuestionnairesFormProps> = (
  props
) => {
  const { submitForm } = props;
  const theme = useTheme();

  const methods = useForm({
    resolver: yupResolver(afterQuestionnairesFormSchema)
  });

  const handleSubmit = methods.handleSubmit(
    (data: afterQuestionnairesFormSchemaType) => {
      submitForm(data);
    }
  );

  return (
    <Box
      sx={{
        border: `1px solid ${theme.palette.border?.main}`,
        backgroundColor: theme.palette.common.white,
        padding: "1.5rem"
      }}
    >
      <FormProvider {...methods}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
        >
          <Typography variant="h2">
            以下のアンケートに回答してください
          </Typography>
          <FormSelect
            name="schoolClass"
            label="クラスを教えてください（該当する選択肢がない場合は先生の指示に従ってください）"
            options={schoolClassesOptions}
            isRequired
            fullWidth
          />
          <FormSelect
            name="studentIDNumber"
            label="あなたの番号を教えてください（該当する選択肢がない場合は先生の指示に従ってください）"
            isRequired
            options={studentIDNumberOptions()}
            fullWidth
          />
          <FormRadioGroup
            name="learnWantedToKnow"
            label="今回の授業を聞いて、知りたかったことを知ることが出来ましたか？"
            options={learnWantedToKnowOptions}
            isRequired
            row
          />
          <FormRadioGroup
            name="isUseful"
            label="今回の授業を聞いて、どれくらい今後の役に立ちそうだと思えましたか？"
            options={isUsefulOptions}
            isRequired
            row
          />
          <FormTextArea
            name="wantedToKnowMore"
            label="今回の授業を聞いて、気になったこと・もっと知りたいと思ったことはありますか？"
            placeholder="例）〇〇のことが気になった、〇〇についてもっと知りたいなど"
            isRequired
          />
          <FormTextArea
            name="wantedToTell"
            label="今回の授業を聞いて、得られた発見を他の人に伝えるとしたら何を伝えますか？"
            placeholder="例）〇〇のことが面白かった、〇〇のことを知ってほしいなど"
            isRequired
          />
          <FormSubmitButton label="送信" />
        </Box>
      </FormProvider>
    </Box>
  );
};

export default BeforeQuestionnairesForm;
