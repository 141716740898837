import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import { useAtomValue } from "jotai";
import { useState } from "react";
import { useLocation } from "react-router-dom";

import ImportantNoticeItem from "features/teachers/components/molecules/common/ImportantNoticeItem";
import Footer from "components/organisms/Footer";
import Header from "components/organisms/Header";
import HeaderSp from "components/organisms/HeaderSp";
import SpDrawer from "components/organisms/SpDrawer";
import { currentUserAtom, isLoggedInAtom } from "store/AuthStore";

import type { Theme } from "@mui/material";
import AlertMessages from "components/molecules/AlertMessages";
import Loading from "components/atoms/Loading";

interface CommonLayoutProps {
  isLoading?: boolean;
  children: React.ReactNode;
}

const CommonLayout: React.FC<CommonLayoutProps> = ({ isLoading, children }) => {
  const theme = useTheme();
  const location = useLocation();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const isLoggedIn = useAtomValue(isLoggedInAtom);
  const currentUser = useAtomValue(currentUserAtom);

  return (
    <Box
      id="layout-root"
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column"
      }}
    >
      <div>
        {isMobile ? (
          <HeaderSp isOpen={drawerIsOpen} setIsOpen={setDrawerIsOpen} />
        ) : (
          <Header />
        )}
      </div>
      {location.pathname !== "/" && <Box sx={theme.mixins.toolbar} />}
      {location.pathname !== "/" &&
        isLoggedIn &&
        !currentUser.is_identification && (
          <ImportantNoticeItem
            title="本人確認のお願い"
            content="授業の実施前にこちらから本人登録をお願いします"
            link="/teachers/mypage/settings?accordion=identification-check"
          />
        )}
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column"
        }}
      >
        {isLoading ? <Loading headerOnly /> : children}
      </Box>
      <Footer />
      {isMobile && (
        <SpDrawer isOpen={drawerIsOpen} setIsOpen={setDrawerIsOpen} />
      )}
      <AlertMessages />
    </Box>
  );
};

export default CommonLayout;
