import React from "react";
import { useHistory } from "react-router-dom";
import { useAtomValue } from "jotai";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";

import RequestInfoBlock from "features/requests/components/molecules/show/RequestInfoBlock";
import TeacherInfoBlock from "features/schools/components/molecules/show/requests/TeacherInfoBlock";
import RequestGrayButton from "features/schools/components/atoms/show/requests/RequestGrayButton";

import { teacherAtom, detailsAtom } from "features/schools/store/requestsStore";

const RequestFellThrough: React.FC = () => {
  const details = useAtomValue(detailsAtom);
  const teacher = useAtomValue(teacherAtom);
  const history = useHistory();
  const theme = useTheme();

  const getBlockText = () => {
    switch (details?.step) {
      case "canceled":
        return "依頼をキャンセルしました。";
      case "denied":
        return "今回は残念ながら辞退となりました。\n他の複業先生にオファーをしてみましょう。";
      case "dead":
        return "承諾期限が切れました。\n依頼はキャンセル扱いとなります。";
      default:
        return "依頼がキャンセルとなりました。";
    }
  };

  return (
    <Box>
      <Box sx={{ margin: "0 auto 1rem" }}>
        <Typography align="center" sx={{ whiteSpace: "pre-wrap" }}>
          {getBlockText()}
        </Typography>
        <RequestGrayButton
          name="複業先生一覧へ"
          handleClick={() => history.push("/teachers")}
        />
      </Box>
      <Box
        sx={{
          borderRadius: 4,
          border: `solid 1px ${theme.palette.border?.main}`,
          overflow: "hidden",
          margin: { xs: "1rem auto", sm: "1.5rem auto" },
          padding: { xs: "1rem", sm: "1.5rem" }
        }}
      >
        {details?.step === "denied" && (
          <Box sx={{ margin: "0 auto 1rem" }}>
            <Typography fontSize=".8rem" color={theme.palette.sub.main}>
              {teacher?.name}さんからのメッセージ
            </Typography>
            <Typography>{details?.reply_comment}</Typography>
          </Box>
        )}
        <TeacherInfoBlock />
      </Box>
      <Box
        sx={{
          borderRadius: 4,
          border: `solid 1px ${theme.palette.border?.main}`,
          overflow: "hidden",
          margin: { xs: "1rem auto", sm: "1.5rem auto" },
          padding: { xs: "1rem", sm: "1.5rem" }
        }}
      >
        <RequestInfoBlock />
      </Box>
    </Box>
  );
};

export default RequestFellThrough;
