import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import Typography from "@mui/material/Typography";
import { useAtom } from "jotai";
import React, { useEffect } from "react";

import CommonLoading from "components/atoms/CommonLoading";
import ErrorAlert from "components/atoms/ErrorAlert";
import LessonItem from "features/schools/components/molecules/mypage/v2/lessons/LessonListItem";
import useGetRequest from "features/schools/hooks/useGetRequest";
import { currentSchoolAtom } from "store/SchoolAuthStore";
import {
  schoolLessonsAtom,
  kindOfSchoolLessonsAtom
} from "store/schools/mypage/LessonsStore";

const LessonLists: React.FC = () => {
  const theme = useTheme();
  const [currentSchool] = useAtom(currentSchoolAtom);
  const [schoolLessons, setSchoolLessons] = useAtom(schoolLessonsAtom);
  const [kindOfSchoolLessons] = useAtom(kindOfSchoolLessonsAtom);

  const { isLoading, isError, setIsError, getRequest } = useGetRequest({
    url: `/api/api/v1/school_teachers/${currentSchool.id}/lessons`
  });

  useEffect(() => {
    const params = {
      status: kindOfSchoolLessons
    };

    getRequest({
      params,
      onSuccess: (data) => {
        setSchoolLessons(data);
      }
    });
  }, [getRequest, setSchoolLessons, kindOfSchoolLessons]);

  return (
    <>
      <ErrorAlert
        isError={isError}
        setIsError={setIsError}
        errorMessage="授業一覧の取得に失敗しました。"
      />
      <Box
        component="section"
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "1rem"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "1rem",
            padding: "1rem",
            backgroundColor: theme.palette.common.white,
            width: "100%"
          }}
        >
          <Box sx={{ display: { xs: "none", sm: "block" }, width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%"
              }}
            >
              <Typography fontWeight="bold" sx={{ flex: 1 }}>
                授業
              </Typography>
              <Typography fontWeight="bold">ステータス</Typography>
            </Box>
            <Divider sx={{ width: "100%" }} />
          </Box>
          {!isLoading ? (
            schoolLessons ? (
              schoolLessons.data.map((lesson, index) => (
                <React.Fragment key={index}>
                  <LessonItem
                    id={lesson.details.id}
                    teacherName={lesson.teacher.name}
                    teacherImage={lesson.teacher.image.url}
                    teacherRank={lesson.teacher.teacher_rank}
                    exactStartAt={new Date(lesson.details.exact_start_at)}
                    schoolMinutes={lesson.details.school_minutes}
                    step={lesson.details.step}
                  />
                  <Divider sx={{ width: "100%" }} />
                </React.Fragment>
              ))
            ) : (
              <Typography color={theme.palette.sub.main}>
                授業がありません
              </Typography>
            )
          ) : (
            <CommonLoading />
          )}
        </Box>
      </Box>
    </>
  );
};

export default LessonLists;
