import { Box, Typography, useTheme } from "@mui/material";
import { useAtom } from "jotai";
import { candidateAtom, recruitmentAtom } from "store/recruitments/ShowStore";
import Button from "components/atoms/mui/Button";
import { formatDateTimeStringWithDay } from "utils/date";
import CandidateCreateFormOverlay from "../../atoms/show/CandidateCreateFormOverlay";
import DetailItem from "../../molecules/DetaiItem";

interface CandidateDetailProps {
  openCancelModal: () => void;
}

const CandidateDetail: React.FC<CandidateDetailProps> = ({
  openCancelModal
}) => {
  const theme = useTheme();
  const [candidate] = useAtom(candidateAtom);
  const [recruitment] = useAtom(recruitmentAtom);

  return (
    <Box
      component="section"
      sx={{
        position:
          candidate?.status !== "candidate_canceled" ? "relative" : "static",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        border:
          candidate?.status !== "candidate_canceled"
            ? `2px solid ${theme.palette.primary.darker}`
            : `1px solid ${theme.palette.border?.main}`,
        borderRadius: "8px",
        backgroundColor: theme.palette.common.white,
        padding: "1rem"
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <Typography variant="h3">
          {candidate?.status !== "candidate_canceled"
            ? "応募内容"
            : "以前の応募内容"}
        </Typography>
        {candidate?.status !== "candidate_canceled" && (
          <CandidateCreateFormOverlay />
        )}
        {recruitment?.step !== "candidate_select" &&
          candidate?.status === "already_applied" && (
            <Button
              variant="text"
              color="secondary"
              size="small"
              disableElevation
              onClick={openCancelModal}
              sx={{
                fontWeight: "bold",
                padding: ".25rem 1rem"
              }}
            >
              応募をキャンセルする
            </Button>
          )}
      </Box>
      {/* MEMO: キャンセル用の処理を追加 */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem"
        }}
      >
        <DetailItem
          title="希望する授業候補日"
          content={
            <div>
              {candidate?.proposed_dates?.data.map((proposedDate, index) => (
                <Typography key={index}>
                  {formatDateTimeStringWithDay(proposedDate.start_at_datetime)}
                </Typography>
              ))}
            </div>
          }
        />
        <DetailItem
          title="こんな授業ができます"
          content={candidate?.lesson_contents}
        />
        <DetailItem title="意気込み" content={candidate?.appeal} />
      </Box>
    </Box>
  );
};

export default CandidateDetail;
