import aspida from "libs/aspida";
import useAspidaSWR from "@aspida/swr";
import { useAtom } from "jotai";
import { useHistory } from "react-router";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import useHeaders from "hooks/useHeaders";
import { useAlertMessages } from "hooks/useAlertMessages";

import { Methods } from "api/api/v1/school_teacher_profiles";
import profileNewFormSchema from "features/schools/constants/profileNewFormSchema";

import {
  schoolDivisionsData,
  schoolTypesData,
  prefecturesData,
  knowReasonsData
} from "store/SchoolMasterData";

const useNewPageApi = () => {
  const headers = useHeaders();
  const history = useHistory();
  const { addErrorMessage, addSuccessMessage } = useAlertMessages();

  const [, setSchoolDivisions] = useAtom(schoolDivisionsData);
  const [, setSchoolTypes] = useAtom(schoolTypesData);
  const [, setPrefectures] = useAtom(prefecturesData);
  const [, setKnowReasons] = useAtom(knowReasonsData);

  const methods = useForm<Methods["post"]["reqBody"]>({
    mode: "onChange",
    resolver: yupResolver(profileNewFormSchema),
    defaultValues: {}
  });

  const { data } = useAspidaSWR(
    aspida(headers).api.v1.school_teacher_profiles.new,
    {
      onSuccess: (data) => {
        setPrefectures(data.prefectures);
        setSchoolTypes(data.school_types);
        setSchoolDivisions(data.school_divisions);
        setKnowReasons(data.know_reasons);
      },
      onError: () => {
        addErrorMessage("データの取得に失敗しました");
        history.push("/schools/login");
      }
    }
  );

  const postNewProfile = async (params: Methods["post"]["reqBody"]) => {
    // MEMO: jsonでの送信のため、ファイル名を決め打ち
    params.image_file_name = "profile";

    try {
      const response = await aspida(
        headers
      ).api.v1.school_teacher_profiles.post({
        body: params
      });
      if (response.status === 200) {
        addSuccessMessage("プロフィールを登録しました");
        history.push("/schools/profile/complete");
      } else {
        addErrorMessage("プロフィールの登録に失敗しました");
      }
    } catch {
      addErrorMessage("プロフィールの登録に失敗しました");
    }
  };

  const handleSubmit = methods.handleSubmit((data) => {
    postNewProfile(data);
  });

  return { methods, data, handleSubmit };
};

export default useNewPageApi;
