import Box from "@mui/material/Box";

import LessonSelectLabel from "features/schools/components/atoms/mypage/v2/lessons/LessonSelectLabel";

import type React from "react";

const LessonSelectLabels: React.FC = () => (
  <Box
    sx={{
      width: "100%",
      display: "flex",
      gap: ".5rem"
    }}
  >
    <LessonSelectLabel label="すべて" value="" />
    <LessonSelectLabel label="授業準備中" value="preparation" />
    <LessonSelectLabel label="授業終了" value="lesson_end" />
  </Box>
);

export default LessonSelectLabels;
