import React from "react";
import { useAtomValue } from "jotai";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";

import PreListItem from "features/lessons/components/atoms/PreListItem";
import PreListItemWithLink, {
  TextWithLinkItemType
} from "features/lessons/components/atoms/PreListItemWithLink";

import {
  detailsAtom,
  meetingSheetInLessonAtom
} from "features/schools/store/lessonsStore";

const PreparationList: React.FC = () => {
  const theme = useTheme();
  const meetingSheetInLesson = useAtomValue(meetingSheetInLessonAtom);
  const details = useAtomValue(detailsAtom);

  const agendaSheetLeading: Array<TextWithLinkItemType> =
    !details?.is_skip_meeting
      ? [
          { kindOf: "text", content: "打ち合わせをしながら" },
          {
            kindOf: "link",
            content: "授業準備シート",
            link: `/schools/mypage/lessons/${details?.id}/agenda`
          },
          {
            kindOf: "text",
            content: "に予定される授業内容を入力した"
          }
        ]
      : [
          {
            kindOf: "link",
            content: "授業準備シート",
            link: `/schools/mypage/lessons/${details?.id}/agenda`
          },
          {
            kindOf: "text",
            content: "に予定される授業内容を入力した"
          }
        ];

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primaryBackground?.main,
        borderRadius: "8px",
        padding: "1.5rem",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "1rem"
      }}
    >
      <Typography variant="h2">事前準備リスト</Typography>
      <Box>
        <PreListItemWithLink title={agendaSheetLeading} />
        <PreListItem
          title={
            !details?.is_skip_meeting
              ? "打ち合わせで緊急連絡先の交換をした"
              : "緊急連絡先を共有した"
          }
        />
        <PreListItem title="授業本番と同じ環境の準備・接続テストをした" />
        {meetingSheetInLesson?.school_advance_todo && (
          <PreListItem
            title="事前準備の確認（当日までにやること）"
            text={meetingSheetInLesson.school_advance_todo}
          />
        )}
      </Box>
    </Box>
  );
};

export default PreparationList;
