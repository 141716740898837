import dayjs from "dayjs";

export const postLessonMeetingPossibleDatimesFormDefaultValues = {
  is_online: true,
  lesson_meeting_possible_datetimes: [
    {
      possible_datetime_from: dayjs()
        .add(7, "day")
        .set("hour", 12)
        .set("minute", 0)
        .toString(),
      possible_datetime_to: dayjs()
        .add(7, "day")
        .set("hour", 17)
        .set("minute", 0)
        .toString()
    }
  ]
};
