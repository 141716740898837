import React from "react";

import useTheme from "@mui/material/styles/useTheme";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const WaitingForCompleteReportBlock: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primaryBackground?.main,
        borderRadius: "8px",
        padding: "1.5rem",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "1rem"
      }}
    >
      <Typography variant="h2">授業お疲れ様でした！</Typography>
      <Typography>複業先生の完了報告をお待ちください。</Typography>
    </Box>
  );
};

export default WaitingForCompleteReportBlock;
