import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Button from "components/atoms/mui/Button";
import { useDialog } from "hooks/useDialog";
import CommonModal from "components/molecules/CommonDialog";
import { Methods as UpdateLessonMethods } from "api/api/v1/lessons/_lessonId@string";
import OptionsItem from "./OptionsItem";

import type { MeetingScheduleStepType } from "../../organisms/MeetingScheduleBlock";

type SelectWayProps = {
  setStep: React.Dispatch<React.SetStateAction<MeetingScheduleStepType>>;
  updateLesson: (data: UpdateLessonMethods["patch"]["reqBody"]) => void;
};

const SelectWay: React.FC<SelectWayProps> = (props) => {
  const { setStep, updateLesson } = props;
  const { isOpen, onOpen, onClose } = useDialog();

  const handleMeetingSkip = () => {
    updateLesson({
      is_skip_meeting: true
    });
    onClose();
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1.5rem"
        }}
      >
        <Typography variant="h2">
          複業先生との打ち合わせを行いましょう
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem"
          }}
        >
          <OptionsItem
            title="打ち合わせ日程を調整する"
            desciption="候補日の選択へ進みます"
            onClick={() => setStep("suggestedDateForm")}
          />
          <OptionsItem
            title="すでに日程が決まっている"
            desciption="日程の入力へ進みます"
            onClick={() => setStep("setDate")}
          />
          <OptionsItem
            title={
              <Typography variant="h3" textAlign="left">
                <Typography
                  variant="h3"
                  component="span"
                  sx={{ display: "inline-block" }}
                >
                  打ち合わせを行わない
                </Typography>
                <Typography
                  variant="body1"
                  component="span"
                  sx={{ display: "inline-block" }}
                >
                  （複業先生も了承済み）
                </Typography>
              </Typography>
            }
            desciption="次のステップへ進みます"
            onClick={onOpen}
          />
        </Box>
      </Box>
      <CommonModal open={isOpen} onClose={onClose}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            justifyContent: "space-between"
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: ".5rem"
            }}
          >
            <Typography fontWeight="bold" fontSize="1.1rem">
              本当に打ち合わせをスキップしますか？
            </Typography>
            <Typography>
              より良い授業を作るため、オンラインでの打ち合わせを推奨しています。
              打ち合わせを行わない場合、複業先生にもチャットなどで確認してから進めてください。
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              justifyContent: "space-between",
              flexDirection: { xs: "column-reverse", sm: "row" }
            }}
          >
            <Button
              variant="outlined"
              color="inherit"
              fullWidth
              onClick={onClose}
            >
              キャンセル
            </Button>
            <Button fullWidth onClick={handleMeetingSkip}>
              打ち合わせをスキップする
            </Button>
          </Box>
        </Box>
      </CommonModal>
    </>
  );
};

export default SelectWay;
