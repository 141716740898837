import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import { useAtom } from "jotai";
import React from "react";

import CommonLoading from "components/atoms/CommonLoading";
import CommonMypageHomeSectionTitle from "components/molecules/CommonMypageHomeSectionTitle";
import RequestItem from "features/schools/components/molecules/mypage/v2/home/RequestItem";
import { schoolRequestsAtom } from "store/schools/mypage/HomeStore";

import RequestIcon from "assets/common/sidebar/unselected/schools/request.svg";
import { Paper, Stack } from "@mui/material";
import { checkDeadline } from "utils/date";

interface HomeRequestsProps {
  isLoading: boolean;
}

const HomeRequests: React.FC<HomeRequestsProps> = ({ isLoading }) => {
  const theme = useTheme();
  const [schoolRequests] = useAtom(schoolRequestsAtom);

  return (
    <Stack component="section" gap={2} width="100%">
      <CommonMypageHomeSectionTitle
        Icon={<img src={RequestIcon} alt="直接依頼状況" />}
        title="直接依頼状況"
        count={schoolRequests?.data_count || 0}
        linkName="すべての依頼を見る"
        link="/schools/mypage/requests"
      />
      <Stack component={Paper} p={2} gap={2} width="100%" sx={{ boxShadow: 0 }}>
        {!isLoading ? (
          schoolRequests ? (
            schoolRequests.data.map((schoolRequest, index) => {
              const { limit } = checkDeadline(
                schoolRequest.details.accept_dead_at
              );

              return (
                <React.Fragment key={index}>
                  <RequestItem
                    id={schoolRequest.details.id}
                    teacherName={schoolRequest.teacher.name}
                    teacherImage={schoolRequest.teacher.image.url}
                    organizationName={schoolRequest.teacher.organization_name}
                    teacherRank={schoolRequest.teacher.teacher_rank}
                    limit={limit}
                    step={schoolRequest.details.step}
                  />
                  <Divider sx={{ width: "100%" }} />
                </React.Fragment>
              );
            })
          ) : (
            <Typography color={theme.palette.sub.main}>
              依頼がありません
            </Typography>
          )
        ) : (
          <CommonLoading />
        )}
      </Stack>
    </Stack>
  );
};

export default HomeRequests;
