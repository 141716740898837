import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import RequestAcceptedLabel from "features/schools/components/atoms/mypage/v2/requests/RequestAcceptedLabel";
import RequestCancelLabel from "features/schools/components/atoms/mypage/v2/requests/RequestCancelLabel";
import RequestDeniedLabel from "features/schools/components/atoms/mypage/v2/requests/RequestDeniedLabel";
import SendRequestLabel from "features/schools/components/atoms/mypage/v2/requests/SendRequestLabel";

import type React from "react";
import RequestItemData from "./RequestItemData";

type RequestBoxProps = {
  id: number;
  teacherName: string;
  teacherImage: string;
  organizationName: string;
  teacherRank: string;
  limit?: number;
  step?: string;
};

const RequestItem: React.FC<RequestBoxProps> = (props) => {
  const {
    id,
    teacherName,
    teacherImage,
    organizationName,
    teacherRank,
    limit,
    step
  } = props;
  const theme = useTheme();

  const getStatusLabel = () => {
    switch (step) {
      case "denied":
        return <RequestDeniedLabel />;
      case "canceled":
      case "dead":
        return <RequestCancelLabel />;
      case "lesson_request":
        return <SendRequestLabel />;
      case "lesson_hold":
        return <RequestAcceptedLabel />;
      default:
        return null;
    }
  };

  return (
    <Link
      href={`/schools/mypage/requests/${id}`}
      sx={{
        width: "100%",
        textDecoration: "none"
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "start", sm: "center" },
          justifyContent: { xs: "start", sm: "space-between" },
          width: "100%",
          gap: { xs: ".5rem", sm: "0" }
        }}
      >
        <Box sx={{ flex: 16 }}>
          <RequestItemData
            teacherName={teacherName}
            teacherImage={teacherImage}
            organizationName={organizationName}
            teacherRank={teacherRank}
          />
        </Box>
        <Box sx={{ display: { xs: "none", sm: "block" }, flex: 4 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center"
            }}
          >
            {getStatusLabel()}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "row", sm: "column" },
            alignItems: "center",
            justifyContent: "center",
            gap: ".5rem",
            flex: 3
          }}
        >
          <Typography fontSize=".8rem" color={theme.palette.sub.main}>
            返答期限
          </Typography>
          {step === "lesson_request" && limit && limit > 0 ? (
            <Typography fontWeight="bold">あと{limit}日</Typography>
          ) : (
            <Typography color={theme.palette.sub.main}>------</Typography>
          )}
        </Box>
      </Box>
    </Link>
  );
};

export default RequestItem;
