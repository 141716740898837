import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import dayjs from "dayjs";

import type React from "react";

import { requestCancelDataType } from "features/schools/hooks/requests/useShowPageApi";

type RequestCancelAlertProps = {
  isOpen: boolean;
  onClose: () => void;
  requestCancel: (data: requestCancelDataType) => void;
};

// TODO: 依頼のキャンセルはスタイルとaspidaを合わせて直す
const RequestCancelAlert: React.FC<RequestCancelAlertProps> = (props) => {
  const { isOpen, onClose, requestCancel } = props;

  const handleRequestCancel = () => {
    requestCancel({
      canceled_at: dayjs().toISOString()
    });
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Fade in={isOpen}>
        <Paper sx={{ padding: "1rem" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h2" align="center">
                依頼をキャンセルしますか？
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Button onClick={handleRequestCancel} fullWidth>
                はい
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button color="inherit" onClick={onClose} fullWidth>
                いいえ
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default RequestCancelAlert;
