import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import Meta from "components/organisms/Meta";
import AiTeachingPlanForm from "features/schools/components/organisms/mypage/AiTeachingPlan/AiTeachingPlanForm";
import FirstView from "features/schools/components/organisms/mypage/AiTeachingPlan/FirstView";
import CommonLayout from "components/layouts/CommonLayout";

import type React from "react";
import useAiTeachingPlanNewPageApi from "features/schools/hooks/mypage/useAiTeachingPlanNewPageApi";
import { FormProvider } from "react-hook-form";

const New: React.FC = () => {
  const theme = useTheme();
  const { form, createNewPlan, schoolGrades, isLoading } =
    useAiTeachingPlanNewPageApi();

  return (
    <CommonLayout isLoading={isLoading}>
      <Meta title="複業先生｜AIで授業案生成" />
      <FirstView />
      <Box sx={{ backgroundColor: theme.palette.backgroundColor?.main }}>
        <Container
          maxWidth="sm"
          sx={{
            padding: "2rem 0",
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem"
          }}
        >
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(createNewPlan)}>
              <AiTeachingPlanForm schoolGrades={schoolGrades ?? []} />
            </form>
          </FormProvider>
        </Container>
      </Box>
    </CommonLayout>
  );
};

export default New;
