import aspida from "libs/aspida";
import useAspidaSWR from "@aspida/swr";
import { useAtom } from "jotai";

import useHeaders from "hooks/useHeaders";
import { useAlertMessages } from "hooks/useAlertMessages";

import { currentSchoolAtom } from "store/SchoolAuthStore";
import { noRatingLessonsAtom } from "features/schools/store/mypageStore";
import {
  schoolLessonsAtom,
  schoolRecruitmentsAtom,
  schoolRequestsAtom
} from "store/schools/mypage/HomeStore";
import { useDialog } from "hooks/useDialog";

const useMypageApi = () => {
  const currentSchool = useAtom(currentSchoolAtom)[0];
  const [, setNoRatingLessons] = useAtom(noRatingLessonsAtom);
  const [, setSchoolLessons] = useAtom(schoolLessonsAtom);
  const [, setSchoolRecruitments] = useAtom(schoolRecruitmentsAtom);
  const [, setSchoolRequests] = useAtom(schoolRequestsAtom);

  const headers = useHeaders();
  const { addErrorMessage } = useAlertMessages();
  const ratingsAttentionModal = useDialog();

  useAspidaSWR(
    aspida(headers).api.v1.school_teachers._schoolTeacherId_string(
      currentSchool.id.toString()
    ).lessons.no_rating,
    {
      onSuccess: (data) => {
        setNoRatingLessons(data.data);
        if (data.data && data.data.length !== 0) {
          ratingsAttentionModal.onOpen();
        }
      },
      onError: () => {
        addErrorMessage("未評価の案件の取得に失敗しました");
      },
      revalidateOnFocus: false
    }
  );

  const { isLoading: lessonsIsLoading } = useAspidaSWR(
    aspida(headers).api.v1.school_teachers._schoolTeacherId_string(
      currentSchool.id.toString()
    ).lessons,
    {
      query: {
        limit: "3"
      },
      onSuccess: (res) => {
        setSchoolLessons(res);
      },
      onError: () => {
        addErrorMessage("授業の取得に失敗しました");
      },
      revalidateOnFocus: false
    }
  );

  const { isLoading: recruitmentsIsLoading } = useAspidaSWR(
    aspida(headers).api.v1.school_teachers._schoolTeacherId_string(
      currentSchool.id.toString()
    ).lesson_recruitments,
    {
      query: {
        limit: "3"
      },
      onSuccess: (res) => {
        setSchoolRecruitments(res);
      },
      onError: () => {
        addErrorMessage("募集情報の取得に失敗しました");
      },
      revalidateOnFocus: false
    }
  );

  const { isLoading: requestsIsLoading } = useAspidaSWR(
    aspida(headers).api.v1.school_teachers._schoolTeacherId_string(
      currentSchool.id.toString()
    ).lesson_requests,
    {
      query: {
        status: "only_request",
        limit: "3"
      },
      onSuccess: (res) => {
        setSchoolRequests(res);
      },
      onError: () => {
        addErrorMessage("依頼の取得に失敗しました");
      },
      revalidateOnFocus: false
    }
  );

  return {
    lessonsIsLoading,
    recruitmentsIsLoading,
    requestsIsLoading,
    ratingsAttentionModal
  };
};

export default useMypageApi;
