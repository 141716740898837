import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import type React from "react";
import { useFormContext } from "react-hook-form";
import { useAtomValue } from "jotai";
import { recruitmentAtom } from "store/schools/recruitments/ShowStore";
import usePrice from "features/schools/hooks/recruitments/usePrice";
import { Typography } from "@mui/material";
import { formatDateTimeStringWithDay } from "utils/date";

interface ImportantInfoBoxProps {
  showDisplay?: boolean;
}

const ImportantInfoBox: React.FC<ImportantInfoBoxProps> = (props) => {
  const { showDisplay } = props;
  const theme = useTheme();
  const { watch } = useFormContext();

  const recruitment = useAtomValue(recruitmentAtom);
  const selectedDateId = watch("proposed_date_id") || 0;
  const { priceBreakdownString, totalPrice } = usePrice();

  const selectedDate = recruitment?.proposed_dates?.data.find(
    (date) => date.id.toString() === selectedDateId.toString()
  );

  const displayTime = formatDateTimeStringWithDay(
    selectedDate?.start_at_datetime || ""
  );

  return (
    <Box
      sx={{
        borderRadius: "8px",
        backgroundColor: theme.palette.primary.lighter,
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
        gap: ".5rem"
      }}
    >
      {showDisplay && (
        <Typography fontWeight="bold">
          {displayTime}（{recruitment?.school_minutes}分間）
        </Typography>
      )}
      <Typography fontWeight="bold">
        料金合計：{totalPrice || "--"}円（税抜）
      </Typography>
      {priceBreakdownString && (
        <Typography color={theme.palette.sub?.main} fontSize=".9rem">
          （{priceBreakdownString}）
        </Typography>
      )}
    </Box>
  );
};

export default ImportantInfoBox;
