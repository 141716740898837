import dayjs from "dayjs";

export const patchMeetingDatimesDefaultValues = {
  meeting_start_at: dayjs()
    .add(7, "day")
    .set("hour", 12)
    .set("minute", 0)
    .toString(),
  is_meeting_online: true
};
