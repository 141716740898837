import React, { useState } from "react";
import { fileType } from "store/RequestShowStore";
import { Page, Document, pdfjs } from "react-pdf";
import { Button, Modal, Box } from "@mui/material";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

type FileContentProps = {
  file: fileType;
};

const SUPPORTED_IMAGE_EXTENSIONS = ["png", "jpg", "jpeg"];

const FileContent: React.FC<FileContentProps> = ({ file }) => {
  const [open, setOpen] = useState(false);
  const [pdfPages, setPdfPages] = useState(0);
  const fileExtension = getFileExtension(file.name).toLowerCase();
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

  const handlePdfOpen = () => {
    setOpen(true);
  };

  const handlePdfClose = () => {
    setOpen(false);
  };

  // ここだけany型使うの許してほしいです。ライブラリのPDF型の型変換がどうしてもうまくいかず...
  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setPdfPages(numPages);
  };

  if (SUPPORTED_IMAGE_EXTENSIONS.includes(fileExtension)) {
    return <img src={file.url} alt={file.name} style={{ maxWidth: "100%" }} />;
  }

  if (fileExtension === "pdf") {
    return (
      <>
        <div style={{ position: "relative", cursor: "pointer" }}>
          <Document file={file.url}>
            <Page pageNumber={1} width={240} />
          </Document>
          <Button
            variant="contained"
            color="secondary"
            onClick={handlePdfOpen}
            style={{
              position: "absolute",
              top: "50%",
              left: "100px",
              transform: "translate(-50px, -50%)",
              opacity: 0.7,
              zIndex: 3
            }}
          >
            プレビュー
          </Button>
        </div>
        <Modal open={open} onClose={handlePdfClose}>
          <Box
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              overflowY: "scroll",
              height: "80%",
              backgroundColor: "white",
              padding: "20px"
            }}
          >
            <Document file={file.url} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(new Array(pdfPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
          </Box>
        </Modal>
      </>
    );
  }

  return null;
};

const getFileExtension = (fileName: string) =>
  fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);

export default FileContent;
