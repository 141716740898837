import React from "react";
import { useAtomValue } from "jotai";

import useTheme from "@mui/material/styles/useTheme";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import dayjs from "dayjs";
import ja from "dayjs/locale/ja";

import { detailsAtom as detailsTeacherAtom } from "features/teachers/stores/lessonsStore";
import { detailsAtom as detailsSchoolAtom } from "features/schools/store/lessonsStore";
import Link from "components/atoms/mui/Link";

const BeforeMeetingBlock: React.FC = () => {
  const theme = useTheme();

  const detailsTeacher = useAtomValue(detailsTeacherAtom);
  const detailsSchool = useAtomValue(detailsSchoolAtom);
  const details = detailsTeacher || detailsSchool;

  const linkTo = detailsTeacher
    ? `/teachers/mypage/lessons/${details?.id}/agenda`
    : `/schools/mypage/lessons/${details?.id}/agenda`;

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primaryBackground?.main,
        borderRadius: "8px",
        padding: "1.5rem",
        width: "100%"
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem"
        }}
      >
        <Typography variant="h2">打ち合わせの準備をしましょう</Typography>
        <Typography>
          {dayjs(details?.meeting_start_at).locale(ja).format("M月D日")}
          の打ち合わせをスムーズに進めるために、
          <Link href={linkTo}>授業準備シート</Link>
          の授業概要を埋めておきましょう。
        </Typography>
      </Box>
    </Box>
  );
};

export default BeforeMeetingBlock;
