import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SectionLoading from "components/atoms/SectionLoading";

import type React from "react";
import { useFormContext } from "react-hook-form";
import { Methods } from "api/api/v1/teachers/_teacherId@string/teacher_bank_accounts";
import HowToWriteAccountHolder from "features/teachers/components/molecules/show/settings/HowToWriteAccountHolder";
import BankAccountDisplay from "./BankAccountDisplay";
import BankAccountForm from "./BankAccountForm";

interface BankAccountContentsProps {
  isLoading: boolean;
  handleDelete: () => void;
  teacherBankAccount?: Methods["get"]["resBody"]["teacher_bank_account"];
}

const BankAccountContents: React.FC<BankAccountContentsProps> = (props) => {
  const { handleDelete, isLoading, teacherBankAccount } = props;
  const {
    formState: { isSubmitting }
  } = useFormContext();

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem",
        width: "100%"
      }}
    >
      <Typography>
        謝礼は、授業・イベント実施翌月末に登録された口座に振り込ませていただきます。
        <br />
        実施前日までにご入力ください。（口座登録が遅れた場合は、振込も遅れる可能性がございます）
        <br />
        振り込み手数料は、講師の方のご負担になります。
        <br />
        <small>
          弊社からの振込は「GMOあおぞらネット銀行」から行います。ご負担いただく手数料は、登録口座が「GMOあおぞらネット銀行」であれば無料、それ以外だと145円（税込）となります。
        </small>
      </Typography>
      <HowToWriteAccountHolder />
      {isLoading ? (
        <SectionLoading />
      ) : teacherBankAccount?.id ? (
        <BankAccountDisplay
          handleDelete={handleDelete}
          teacherBankAccount={teacherBankAccount}
        />
      ) : (
        <BankAccountForm />
      )}
      {isSubmitting && <SectionLoading hasColor />}
    </Box>
  );
};

export default BankAccountContents;
