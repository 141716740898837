import { atom } from "jotai";
import { Methods as GetLessonMethods } from "api/api/v1/school_teachers/_schoolTeacherId@string/lessons";
import { Methods as GetRecruitmentMethods } from "api/api/v1/school_teachers/_schoolTeacherId@string/lesson_recruitments";
import { Methods as GetRequestMethods } from "api/api/v1/school_teachers/_schoolTeacherId@string/lesson_requests";

export const schoolRequestsAtom = atom<
  GetRequestMethods["get"]["resBody"] | null
>(null);

export const schoolLessonsAtom = atom<
  GetLessonMethods["get"]["resBody"] | null
>(null);

export const schoolRecruitmentsAtom = atom<
  GetRecruitmentMethods["get"]["resBody"] | null
>(null);
