import * as yup from "yup";
import { Methods } from "api/api/v1/lessons/_lessonId@string/lesson_meeting_possible_datetimes";
import dayjs from "dayjs";

import { futureDateStringSchema } from "utils/yupUtils";

type postLessonMeetingPossibleDatimesFormSchemaType =
  Methods["post"]["reqBody"];

export const postLessonMeetingPossibleDatimesFormSchema: yup.ObjectSchema<postLessonMeetingPossibleDatimesFormSchemaType> =
  yup.object().shape({
    is_online: yup.boolean().required("オンラインか対面かを選択してください"),
    lesson_meeting_possible_datetimes: yup
      .array()
      .of(
        yup.object().shape({
          possible_datetime_from: futureDateStringSchema
            .required("日時を選択してください")
            .test(
              "is-valid-datetime",
              "開始日時と終了日時は1時間以上あけてください",
              (value, context) => {
                const from = dayjs(value);
                const to = dayjs(context.parent.possible_datetime_to);
                return (
                  to.isAfter(from.add(1, "hour")) ||
                  to.isSame(from.add(1, "hour"))
                );
              }
            ),
          possible_datetime_to: futureDateStringSchema
            .required("日時を選択してください")
            .test(
              "is-valid-datetime",
              "開始日時と終了日時は1時間以上あけてください",
              (value, context) => {
                const from = dayjs(context.parent.possible_datetime_from);
                const to = dayjs(value);
                return (
                  to.isAfter(from.add(1, "hour")) ||
                  to.isSame(from.add(1, "hour"))
                );
              }
            )
        })
      )
      .test(
        "is-unique-datetime",
        "被りのない日時を選択してください",
        (value) => {
          if (!value) return false;

          const datetimeList = value.map((v) => ({
            from: dayjs(v.possible_datetime_from),
            to: dayjs(v.possible_datetime_to)
          }));
          for (let i = 0; i < datetimeList.length; i++) {
            for (let j = i + 1; j < datetimeList.length; j++) {
              if (
                datetimeList[i].from.isBefore(datetimeList[j].to) &&
                datetimeList[i].to.isAfter(datetimeList[j].from)
              ) {
                return false;
              }
            }
          }
          return true;
        }
      )
  });
