import { Stack, useMediaQuery } from "@mui/material";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import { useAtom } from "jotai";

import Meta from "components/organisms/Meta";
import Sidebar from "features/schools/components/organisms/common/Sidebar";
import PublicCheckAlert from "features/schools/components/organisms/mypage/v2/PublicCheckAlert";
import RatingsAttentionModal from "features/schools/components/organisms/show/requests/RatingsAttentionModal";
import Guides from "features/schools/components/organisms/mypage/v2/home/Guides";
import HomeLessons from "features/schools/components/organisms/mypage/v2/home/HomeLessons";
import TitleBlock from "features/schools/components/organisms/mypage/v2/TitleBlock";
import CommonLayout from "components/layouts/CommonLayout";
import { currentSchoolAtom } from "store/SchoolAuthStore";

import type { Theme } from "@mui/material/styles";
import type React from "react";
import useMypageApi from "features/schools/hooks/mypage/useMypageApi";
import AnnouncementDialog from "components/organisms/AnnouncementDialog";
import useAnnouncement from "features/schools/hooks/useAnnouncement";
import HomeRecruitments from "../../organisms/mypage/v2/home/HomeRecruitments";
import HomeRequests from "../../organisms/mypage/v2/home/HomeRequests";

const Mypage: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const [currentSchool] = useAtom(currentSchoolAtom);

  const {
    lessonsIsLoading,
    recruitmentsIsLoading,
    requestsIsLoading,
    ratingsAttentionModal
  } = useMypageApi();
  const { announcementDialogOpen, announcements, confirmAnnouncement } =
    useAnnouncement();

  return (
    <CommonLayout>
      <Meta title="複業先生｜教員マイページ" />
      <Box
        sx={{
          backgroundColor: theme.palette.backgroundColor?.main,
          minHeight: { sx: "calc(100vh - 56px)", sm: "calc(100vh - 64px)" }
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: "flex",
            gap: "1.5rem",
            padding: "1.5rem"
          }}
        >
          {!isMobile && <Sidebar />}
          <Stack spacing={2} width="100%">
            {currentSchool.is_public || <PublicCheckAlert />}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "1.5rem",
                flexGrow: 1
              }}
            >
              <TitleBlock title="マイページ" />
              <Guides />
              <Box
                sx={{
                  display: "flex",
                  alignItems: { sx: "center", md: "start" },
                  gap: "1.5rem",
                  width: "100%",
                  flexDirection: { xs: "column", md: "row" }
                }}
              >
                <HomeRequests isLoading={requestsIsLoading} />
                <HomeRecruitments isLoading={recruitmentsIsLoading} />
              </Box>
              <HomeLessons isLoading={lessonsIsLoading} />
            </Box>
          </Stack>
        </Container>
      </Box>
      <RatingsAttentionModal
        isOpen={ratingsAttentionModal.isOpen}
        onClose={ratingsAttentionModal.onClose}
      />
      <AnnouncementDialog
        isOpen={announcementDialogOpen}
        announcements={announcements}
        confirmAnnouncement={confirmAnnouncement}
      />
    </CommonLayout>
  );
};

export default Mypage;
