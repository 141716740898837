import { useAtom } from "jotai";
import { teacherCommonDataAtom } from "features/teachers/stores/commonDataStore";
import { isLoggedInAtom } from "store/AuthStore";

import aspida from "libs/aspida";
import useAspidaSWR from "@aspida/swr";

import useHeaders from "hooks/useHeaders";

const useCommonData = () => {
  const [isLoggedIn] = useAtom(isLoggedInAtom);
  const [, setTeacherCommonData] = useAtom(teacherCommonDataAtom);
  const headers = useHeaders();

  const { mutate } = useAspidaSWR(
    aspida(headers).api.v1.tools.teacher_common_data,
    {
      enabled: isLoggedIn,
      onSuccess: (data) => {
        setTeacherCommonData(data);
      }
    }
  );

  return { mutate };
};

export default useCommonData;
