import { useAtom } from "jotai";

import { FormProvider, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import useTheme from "@mui/material/styles/useTheme";
import Box from "@mui/material/Box";

import IdentificationAlert from "features/teachers/components/molecules/show/requests/IdentificationAlert";

import FormRadioGroup from "components/molecules/FormRadioGroup";
import FormTextArea from "components/molecules/FormTextArea";
import FormSubmitButton from "components/molecules/FormSubmitButton";

import { currentUserAtom } from "store/AuthStore";
import { possibleDatesAtom } from "features/teachers/stores/requestsStore";

import replyRequestFormSchema from "features/teachers/constants/requests/replyRequestFormSchema";

import { replyRequestDataType } from "features/teachers/hooks/request/useShowPageApi";

import { isTrue } from "utils/checkBool";

import type React from "react";
import { formatDateTimeStringWithDay } from "utils/date";

type ReplyFormProps = {
  replyRequest: (data: replyRequestDataType) => Promise<void>;
};

const ReplyForm: React.FC<ReplyFormProps> = (props) => {
  const { replyRequest } = props;
  const theme = useTheme();
  const [possibleDates] = useAtom(possibleDatesAtom);
  const [currentUser] = useAtom(currentUserAtom);

  // FIXME is_acceptedの初期値指定を外部ファイルに切り出すとresolverが型エラーを起こすため直書き
  const methods = useForm({
    resolver: yupResolver(replyRequestFormSchema),
    defaultValues: {
      is_accepted: true
    }
  });

  const watchedIsAccepted = useWatch({
    control: methods.control,
    name: "is_accepted"
  });
  // MEMO: isAcceptedをboolで固めているはずなのにstringが入ってしまうため、booleanに変換
  const isAccepted = isTrue(watchedIsAccepted || "");

  const handleSubmit = methods.handleSubmit((data: replyRequestDataType) => {
    replyRequest(data);
  });

  return (
    <Box
      sx={{
        backgroundColor: "#FFF5DD",
        padding: "1.5rem",
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem"
      }}
    >
      <FormProvider {...methods}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
        >
          {!currentUser.is_identification && <IdentificationAlert />}
          <FormRadioGroup
            name="is_accepted"
            label="授業の可否"
            options={[
              { value: true, label: "承諾する" },
              { value: false, label: "辞退する" }
            ]}
            isRequired
            raidoBackgroundColor={theme.palette.common.white}
          />
          {isAccepted && possibleDates && possibleDates.length !== 0 && (
            <FormRadioGroup
              name="possible_date_id"
              label="授業日時"
              options={possibleDates.map((date) => ({
                value: date.id || "",
                label: formatDateTimeStringWithDay(date.start_at_datetime || "")
              }))}
              isRequired
              raidoBackgroundColor={theme.palette.common.white}
            />
          )}
          <FormTextArea
            name="reply_comment"
            label="学校へ向けてメッセージを入力してください。"
            placeholder="例：よろしくお願い致します。&#13;&#10;
            （断る場合）大変恐縮ながら、今回は仕事の繁忙期のため都合が合わず、辞退させて頂きます。またの機会がございましたら、どうぞよろしくお願いいたします。"
            isRequired
          />
          <FormSubmitButton label="送信する" />
        </Box>
      </FormProvider>
    </Box>
  );
};

export default ReplyForm;
