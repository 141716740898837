import React from "react";
import { useAtomValue } from "jotai";

import dayjs from "dayjs";

import useTheme from "@mui/material/styles/useTheme";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Button from "@mui/material/Button";

import { detailsAtom } from "features/schools/store/lessonsStore";
import { formatDateStringWithDay } from "utils/date";

const ToRatingBlock: React.FC = () => {
  const theme = useTheme();
  const details = useAtomValue(detailsAtom);

  const handleScrollToRating = () => {
    const ratingSectionWrapper = document.getElementById(
      "rating-section-wrapper"
    );
    if (ratingSectionWrapper) {
      ratingSectionWrapper.scrollIntoView({ behavior: "smooth" });
    }
  };

  const deadlineFormat = details?.lesson_completed_at
    ? formatDateStringWithDay(
        dayjs(details.lesson_completed_at).add(1, "week").toDate()
      )
    : "";

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primaryBackground?.main,
        borderRadius: "8px",
        padding: "1.5rem",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "1rem"
      }}
    >
      <Typography variant="h2">授業の評価をお願いします。</Typography>
      <Typography>
        授業お疲れ様でした。今回の授業と先生の対応について、それそれ評価をお願いします。
      </Typography>
      <Typography>期限：{deadlineFormat}まで</Typography>
      <Button onClick={handleScrollToRating}>授業を評価する</Button>
    </Box>
  );
};

export default ToRatingBlock;
