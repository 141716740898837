import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import EditableText from "components/atoms/lessons/EditableText";
import Button from "components/atoms/mui/Button";
import FormDatetime from "components/molecules/FormDatetime";
import FormSubmitButton from "components/molecules/FormSubmitButton";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { formatDateTimeStringWithDay } from "utils/date";
import { futureDateStringSchema } from "utils/yupUtils";
import * as yup from "yup";

interface MeetingDateFieldProps {
  meeting_start_at?: string;
  onSubmit: (data: { meeting_start_at: string }) => void;
}

const MeetingDateField: React.FC<MeetingDateFieldProps> = ({
  meeting_start_at,
  onSubmit
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const dateString = meeting_start_at
    ? `${formatDateTimeStringWithDay(meeting_start_at)} -`
    : undefined;
  const form = useForm({
    resolver: yupResolver(
      yup.object().shape({
        meeting_start_at:
          futureDateStringSchema.required("打ち合わせ日時を選択してください")
      })
    )
  });
  const { reset } = form;

  useEffect(() => {
    if (meeting_start_at) {
      reset({ meeting_start_at });
      setIsEditing(false);
    }
  }, [meeting_start_at]);

  return isEditing ? (
    <FormProvider {...form}>
      <Box component="form" onSubmit={form.handleSubmit(onSubmit)}>
        <FormDatetime
          name="meeting_start_at"
          label=""
          noLabel
          minDate={new Date()}
        />
        <Box sx={{ display: "flex", mt: "16px", gap: "8px" }}>
          <Button
            variant="outlined"
            onClick={() => setIsEditing(false)}
            sx={{ padding: "0 24px", height: "32px" }}
          >
            キャンセル
          </Button>
          <FormSubmitButton
            label="保存する"
            sx={{ padding: "0 24px", height: "32px" }}
          />
        </Box>
      </Box>
    </FormProvider>
  ) : (
    <EditableText value={dateString} onChange={() => setIsEditing(true)} />
  );
};

export default MeetingDateField;
