import React from "react";
import { useAtomValue } from "jotai";

import useTheme from "@mui/material/styles/useTheme";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import {
  detailsAtom,
  lessonAmountWithoutOptionsAtom
} from "features/schools/store/lessonsStore";

const AmountDisplay: React.FC = () => {
  const theme = useTheme();
  const details = useAtomValue(detailsAtom);
  const lessonAmountWithoutOptions = useAtomValue(
    lessonAmountWithoutOptionsAtom
  );

  return (
    <Box
      sx={{
        border: `2px solid ${theme.palette.primary.main} `,
        borderRadius: "8px",
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
        gap: "1rem"
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: ".5rem"
        }}
      >
        <Typography fontWeight="bold">合計金額</Typography>
        <Typography fontSize="1.5rem" fontWeight="bold">
          ¥{details?.amount.toLocaleString()}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ position: "relative" }}>
        <Typography
          fontWeight="bold"
          sx={{
            color: theme.palette.sub.main,
            position: "absolute",
            top: 0,
            left: 0
          }}
        >
          金額の内訳
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "start", sm: "center" },
            justifyContent: "space-between",
            maxWidth: "600px",
            gap: { xs: "1rem", sm: "0" }
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: ".5rem",
              justifyContent: { xs: "start", sm: "center" },
              flex: 1,
              marginTop: { xs: "2rem", sm: "1.25rem" }
            }}
          >
            <Typography fontWeight="bold">基本料金</Typography>
            <Typography fontSize="1.2rem" fontWeight="bold">
              ¥{lessonAmountWithoutOptions.toLocaleString()}
            </Typography>
          </Box>
          {details?.lesson_options && details.lesson_options.length > 0 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: ".5rem"
              }}
            >
              <Typography fontWeight="bold">サポート料金</Typography>
              {details.lesson_options.map((lessonOption) => (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: ".5rem"
                  }}
                >
                  <CheckCircleIcon fontSize="small" color="primary" />
                  <Typography key={lessonOption.id}>
                    {lessonOption.name}：¥{lessonOption.amount.toLocaleString()}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default AmountDisplay;
