import React from "react";
import { useAtomValue } from "jotai";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import dayjs from "dayjs";
import ja from "dayjs/locale/ja";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import useMediaQuery from "@mui/material/useMediaQuery";

import useTheme from "@mui/material/styles/useTheme";
import Typography from "@mui/material/Typography";
import { yupResolver } from "@hookform/resolvers/yup";

import { lessonMeetingPossibleDatetimesAtom } from "features/teachers/stores/lessonsStore";

import FormDatetime from "components/molecules/FormDatetime";

import { getPostSelectMeetingPossibleDatetimesSchema } from "features/teachers/constants/mypage/lessons/postSelectMeetingPossibleDatetimesSchema";
import FormLabel from "components/atoms/FormLabel";
import FormSubmitButton from "components/molecules/FormSubmitButton";
import Button from "components/atoms/mui/Button";

import { postSelectMeetingPossibleDatetimesType } from "features/teachers/hooks/lessons/useShowPageApi";
import useSelectDatetimeOptions from "features/teachers/hooks/lessons/useSelectDatetimeOptions";
import { formatDateTimeString } from "utils/date";

type SelectCandidateBlockProps = {
  postSelectMeetingPossibleDatetimes: (
    values: postSelectMeetingPossibleDatetimesType
  ) => void;
};

const SelectCandidateBlock: React.FC<SelectCandidateBlockProps> = (props) => {
  const { postSelectMeetingPossibleDatetimes } = props;
  const theme = useTheme();
  const lessonMeetingPossibleDatetimes = useAtomValue(
    lessonMeetingPossibleDatetimesAtom
  );
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // MEMO: デフォルト値系統
  const meetingStartAtDefault =
    lessonMeetingPossibleDatetimes?.[0]?.possible_datetime_from;
  const allowedDates = lessonMeetingPossibleDatetimes?.map(
    (datetime) => new Date(datetime.possible_datetime_from || "")
  );

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(getPostSelectMeetingPossibleDatetimesSchema()),
    defaultValues: {
      meeting_start_at: meetingStartAtDefault || "",
      is_all_reject: false
    }
  });

  const selectedMeetingStartAt = useWatch({
    control: methods.control,
    name: "meeting_start_at"
  });

  const { customHoursOptions, customMinutesOptions } = useSelectDatetimeOptions(
    selectedMeetingStartAt
  );

  const meetingIsOnline = () => {
    if (lessonMeetingPossibleDatetimes) {
      return lessonMeetingPossibleDatetimes?.some(
        (datetime) => datetime.is_online
      );
    }
    // MEMO: 基本はオンライン前提のため、データがない場合もオンラインとして扱う
    return true;
  };

  const handleAllReject = () => {
    postSelectMeetingPossibleDatetimes({
      is_all_reject: true
    });
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primaryBackground?.main,
        borderRadius: "8px",
        padding: "1.5rem",
        width: "100%"
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem"
        }}
      >
        <Box>
          <Typography variant="h2">学校との打ち合わせを行いましょう</Typography>
          <Typography>
            学校から打ち合わせ日程の提案が届いています。打ち合わせは1時間となりますので、提案された日時の中から1時間確保できる時間で、開始時間を選択してください。
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem"
          }}
        >
          <Box
            sx={{
              border: `1px solid ${theme.palette.border?.main}`,
              borderRadius: "8px",
              backgroundColor: theme.palette.common.white,
              padding: "1rem"
            }}
          >
            <Typography>
              <Typography fontWeight="bold" component="span">
                実施方法：
              </Typography>
              <Typography component="span">
                {meetingIsOnline() ? "オンライン" : "対面"}（1時間）
              </Typography>
            </Typography>
            <Typography>
              <Typography fontWeight="bold" component="span">
                打ち合わせ候補日：
              </Typography>
              <Typography component="span">
                {lessonMeetingPossibleDatetimes
                  ?.map(
                    (datetime) =>
                      `${formatDateTimeString(datetime.possible_datetime_from || "")}
                    ~ ${formatDateTimeString(datetime.possible_datetime_to || "")}`
                  )
                  .join(", ")}
              </Typography>
            </Typography>
          </Box>
          <FormProvider {...methods}>
            <Box
              component="form"
              onSubmit={methods.handleSubmit(
                postSelectMeetingPossibleDatetimes
              )}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: ".5rem"
                }}
              >
                <FormLabel labelName="都合がつく日程を入力" isRequired />
                <Box>
                  <Stack direction={isMobile ? "column" : "row"} gap={1}>
                    <Typography fontWeight="bold" sx={{ width: "100%" }}>
                      打ち合わせ日
                    </Typography>
                    <Typography fontWeight="bold" sx={{ minWidth: 120 }}>
                      開始時間（1時間）
                    </Typography>
                    <Box sx={{ minWidth: 120 }} />
                  </Stack>
                  <FormDatetime
                    noLabel
                    label=""
                    name="meeting_start_at"
                    allowedDates={allowedDates}
                    hoursOptions={customHoursOptions}
                    minutesOptions={customMinutesOptions}
                  />
                </Box>
                <Typography color={theme.palette.sub.main}>
                  選択された打ち合わせの予定：
                  {dayjs(selectedMeetingStartAt)
                    .locale(ja)
                    .format("M月D日（dd） HH:mm")}
                  ~
                  {dayjs(selectedMeetingStartAt)
                    .add(1, "hours")
                    .locale(ja)
                    .format("HH:mm")}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "1rem",
                  justifyContent: "flex-end"
                }}
              >
                <Button
                  variant="text"
                  color="secondary"
                  isLink
                  onClick={handleAllReject}
                >
                  参加可能な日程がない場合（別日程でお願いする）
                </Button>
                <FormSubmitButton
                  label="打ち合わせ日程を確定する"
                  disabled={!methods.formState.isValid}
                />
              </Box>
            </Box>
          </FormProvider>
        </Box>
      </Box>
    </Box>
  );
};

export default SelectCandidateBlock;
