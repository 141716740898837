import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import FormCheckBox from "components/molecules/FormCheckBox";

import Typography from "@mui/material/Typography";
import type React from "react";
import { Stack, useTheme } from "@mui/material";
import { Methods } from "api/api/v1/lesson_requests/_lessonRequestId/lesson_request_comments";
import postChatFormSchema from "features/requests/constants/postChatFormShema";
import FormSubmitButton from "components/molecules/FormSubmitButton";
import FileUploadModal from "features/lessons/components/molecules/FileUploadModal";
import FileUploadLoading from "features/lessons/components/molecules/FileUploadLoading";

import { useDialog } from "hooks/useDialog";
import FormTextArea from "components/molecules/FormTextArea";
import IconPopover from "components/atoms/IconPopover";

type MessageFieldProps = {
  postRequestComments: (data: Methods["post"]["reqBody"]) => any;
};

const MessageField: React.FC<MessageFieldProps> = (props) => {
  const { postRequestComments } = props;
  const fileUploadModal = useDialog();
  const theme = useTheme();
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(postChatFormSchema)
  });

  const onSubmit = methods.handleSubmit(async (data) => {
    await postRequestComments(data);
    methods.reset();
  });

  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={onSubmit} py={1}>
        <Stack gap={1}>
          <Stack direction="row" alignItems="center">
            <FormCheckBox
              name="is_office_notification"
              label="事務局からの回答を希望"
            />
            <IconPopover
              variant="question"
              text="事務局にサポートをお願いしたい場合はチェックしてください"
            />
          </Stack>
          <FormTextArea
            name="comment"
            label=""
            placeholder="メッセージを入力してください"
            noLabel
          />
          {methods.formState.errors.file?.message && (
            <Typography color={theme.palette.error.main} fontSize=".8rem">
              {methods.formState.errors.file?.message.toString()}
            </Typography>
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <Button
              variant="text"
              startIcon={
                <InsertDriveFileOutlinedIcon sx={{ fontSize: ".8rem" }} />
              }
              onClick={fileUploadModal.onOpen}
              sx={{
                padding: 0,
                color: theme.palette.link?.main,
                textDecoration: "underline"
              }}
            >
              ファイル送信
            </Button>
            <FormSubmitButton label="送信" size="small" />
          </Box>
        </Stack>
      </Box>
      <FileUploadModal
        isOpen={fileUploadModal.isOpen}
        onClose={fileUploadModal.onClose}
        onSubmit={onSubmit}
      />
      <FileUploadLoading
        isSubmitting={
          methods.formState.isSubmitting && !!methods.getValues("file")
        }
      />
    </FormProvider>
  );
};

export default MessageField;
