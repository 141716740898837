import { atom } from "jotai";
import type { Methods } from "api/api/v1/school_teachers/_schoolTeacherId@string/lesson_recruitments/_lessonRecrutimentId@string";

// MEMO: Acquired data control
type ResBodyType = Methods["get"]["resBody"];
export const candidatesCountAtom = atom(0);
export const candidatesAtom = atom<ResBodyType["candidates"]["data"]>([]);
export const recruitmentAtom = atom<ResBodyType["details"] | undefined>(
  undefined
);
export const lessonOptionsAtom = atom<ResBodyType["lesson_options"]>([]);
export const selectCandidateFormStepAtom = atom<number>(1);

export const recruitmentIdAtom = atom<number | null>(null);
export const newLessonIdAtom = atom<number | null>(null);
