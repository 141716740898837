import React from "react";
import { useAtomValue } from "jotai";
import Container from "@mui/material/Container";
import CommonLayout from "components/layouts/CommonLayout";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import Meta from "components/organisms/Meta";

import Loading from "components/atoms/Loading";
import { useParams } from "react-router-dom";

import useShowPageApi from "features/teachers/hooks/lessons/useShowPageApi";

import LessonFlowSection from "features/teachers/components/organisms/mypage/lessons/organisms/LessonFlowSection";
import LessonInfoSection from "features/teachers/components/organisms/mypage/lessons/organisms/LessonInfoSection";
import Chat from "features/lessons/components/organisms/Chat";
import SelectCandidateBlock from "features/teachers/components/organisms/mypage/lessons/organisms/SelectCandidateBlock";
import SetMeetingPlaceBlock from "features/lessons/components/organisms/SetMeetingPlaceBlock";
import BeforeMeetingBlock from "features/lessons/components/organisms/BeforeMeetingBlock";
import WaitingForMeetingSuggetionBlock from "features/teachers/components/organisms/mypage/lessons/organisms/WaitingForMeetingSuggetionBlock";
import PreparationList from "features/teachers/components/organisms/mypage/lessons/organisms/PreparationList";
import LessonCompleteReportBlock from "features/teachers/components/organisms/mypage/lessons/organisms/LessonCompleteReportBlock";
import LessonCompletedBlock from "features/teachers/components/organisms/mypage/lessons/organisms/LessonCompletedBlock";

import { detailsAtom } from "features/teachers/stores/lessonsStore";
import LessonPlaceBlock from "features/lessons/components/organisms/LessonPlaceBlock";

type paramsTypes = {
  id: string;
};

const Show: React.FC = () => {
  const details = useAtomValue(detailsAtom);
  const { id } = useParams<paramsTypes>();
  const theme = useTheme();

  const {
    getLessonIsLoading,
    postRequestComments,
    deleteRequestComments,
    postSelectMeetingPossibleDatetimes,
    updateLesson
  } = useShowPageApi(id);

  const getTaskBlock = () => {
    switch (details?.step) {
      case "suggest_meeting_date":
        return <WaitingForMeetingSuggetionBlock />;
      case "select_meeting_date":
        return (
          <SelectCandidateBlock
            postSelectMeetingPossibleDatetimes={
              postSelectMeetingPossibleDatetimes
            }
          />
        );
      case "adjust_meeting_place":
        return <SetMeetingPlaceBlock updateLesson={updateLesson} />;
      case "before_meeting":
        return <BeforeMeetingBlock />;
      case "preparation":
        if (
          (details?.is_online && !details?.lesson_url) ||
          (!details?.is_online && !details?.place)
        ) {
          return <LessonPlaceBlock updateLesson={updateLesson} />;
        }
        return <PreparationList />;
      case "lesson_end":
        return <LessonCompleteReportBlock updateLesson={updateLesson} />;
      case "completed":
        return <LessonCompletedBlock />;
      default:
        return null;
    }
  };

  return (
    <>
      <Meta
        title="複業先生 | 授業詳細・進行管理"
        description="成立した授業の詳細情報と進行状況を確認するページです。"
      />
      <CommonLayout>
        {getLessonIsLoading ? (
          <Loading />
        ) : (
          <>
            <LessonInfoSection />
            <Box sx={{ backgroundColor: theme.palette.backgroundColor?.main }}>
              <Container
                maxWidth="lg"
                sx={{
                  width: "100%",
                  padding: { xs: 0, sm: "1.5rem" }
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: { xs: 0, md: "1.5rem" }
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1.5rem",
                      width: { xs: "100%", md: "75%" }
                    }}
                  >
                    {getTaskBlock()}
                    <LessonFlowSection updateLesson={updateLesson} />
                  </Box>
                  <Box
                    sx={{
                      width: { xs: "auto", md: "320px" }
                    }}
                  >
                    <Chat
                      postRequestComments={postRequestComments}
                      deleteRequestComments={deleteRequestComments}
                      variant="teacher"
                    />
                  </Box>
                </Box>
              </Container>
            </Box>
          </>
        )}
      </CommonLayout>
    </>
  );
};

export default Show;
