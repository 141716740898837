import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";
import { useAtom } from "jotai";
import React, { useEffect } from "react";

import CommonMypageHomeSectionTitle from "components/molecules/CommonMypageHomeSectionTitle";
import RecruitmentItem from "features/teachers/components/molecules/mypage/v2/home/RecruitmentItem";
import ErrorAlert from "components/atoms/ErrorAlert";
import CommonLoading from "components/atoms/CommonLoading";
import NoContentItem from "features/teachers/components/molecules/mypage/v2/home/NoContentItem";

import useGetRequest from "features/teachers/hooks/useGetRequest";

import { currentUserAtom } from "store/AuthStore";
import { teacherRecruitmentsAtom } from "store/teachers/mypage/HomeStore";

import RecruitmentIcon from "assets/common/sidebar/unselected/teachers/recruitment.svg";

const Recruitments: React.FC = () => {
  const theme = useTheme();
  const [currentUser] = useAtom(currentUserAtom);
  const [teacherRecruitments, setTeacherRecruitments] = useAtom(
    teacherRecruitmentsAtom
  );

  const { isLoading, isError, setIsError, getRequest } = useGetRequest({
    url: `/api/api/v1/teachers/${currentUser.id}/lesson_recruitments`
  });

  useEffect(() => {
    const params = {
      limit: 3
    };

    getRequest({
      params,
      onSuccess: (data) => {
        setTeacherRecruitments(data);
      }
    });
  }, [getRequest, setTeacherRecruitments]);

  return (
    <>
      <ErrorAlert
        isError={isError}
        setIsError={setIsError}
        errorMessage="応募情報の取得に失敗しました"
      />
      <Box
        component="section"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          width: "100%",
          height: "100%"
        }}
      >
        <CommonMypageHomeSectionTitle
          Icon={<img src={RecruitmentIcon} alt="応募済み" />}
          title="応募済み"
          count={teacherRecruitments?.data_count || 0}
          linkName="すべての応募を見る"
          link="/teachers/mypage/recruitments"
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "1rem",
            padding: "1rem",
            backgroundColor: theme.palette.common.white,
            width: "100%",
            height: "100%"
          }}
        >
          {!isLoading ? (
            teacherRecruitments && teacherRecruitments.data.length > 0 ? (
              teacherRecruitments.data.map((teacherRecruitment, index) => (
                <React.Fragment key={index}>
                  <RecruitmentItem
                    id={teacherRecruitment.details.id}
                    title={teacherRecruitment.details.title}
                    deadLine={
                      new Date(teacherRecruitment.details.recruitment_dead_at)
                    }
                    status={teacherRecruitment.details.step}
                    candidateStatus={teacherRecruitment.candidate.status || ""}
                  />
                  <Divider sx={{ width: "100%" }} />
                </React.Fragment>
              ))
            ) : (
              <NoContentItem
                title="応募済みの授業はありません"
                lead="興味のある授業に応募してみましょう"
                link="/recruitments"
                buttonText="授業の募集を探す"
              />
            )
          ) : (
            <CommonLoading />
          )}
        </Box>
      </Box>
    </>
  );
};

export default Recruitments;
