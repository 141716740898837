import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useMediaQuery } from "@mui/material";
import Container from "@mui/material/Container";

import Meta from "components/organisms/Meta";
import Sidebar from "features/teachers/components/organisms/common/Sidebar";
import Lessons from "features/teachers/components/templates/mypage/Lessons";
import CommonLayout from "components/layouts/CommonLayout";

import type { Theme } from "@mui/material/styles";
import type React from "react";

const MyLessons: React.FC = () => {
  const theme = useTheme();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <CommonLayout>
      <Meta title="複業先生｜授業進行管理" />
      <Box
        sx={{
          backgroundColor: theme.palette.backgroundColor?.main,
          minHeight: { xs: "calc(100vh - 56px)", sm: "calc(100vh - 64px)" }
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: "flex",
            gap: "1.5rem",
            padding: "1.5rem"
          }}
        >
          {!isMobile && <Sidebar />}
          <Lessons />
        </Container>
      </Box>
    </CommonLayout>
  );
};

export default MyLessons;
