import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useAtomValue } from "jotai";
import { recruitmentAtom, candidateAtom } from "store/recruitments/ShowStore";
import Button from "components/atoms/mui/Button";
import { formatDateString } from "utils/date";

interface StatusBoxProps {
  message: string;
  buttonText?: string;
  buttonLink?: string;
  backgroundColor?: string;
}

const StatusBox: React.FC<StatusBoxProps> = ({
  message,
  buttonText,
  buttonLink,
  backgroundColor
}) => {
  const theme = useTheme();
  const history = useHistory();
  return (
    <Box
      sx={{
        backgroundColor,
        borderRadius: "8px",
        padding: "1rem",
        display: "flex",
        gap: "1rem",
        alignItems: { xs: "flex-start", sm: "center" },
        justifyContent: "space-between",
        flexDirection: { xs: "column", sm: "row" }
      }}
    >
      <Typography color={theme.palette.sub.main}>{message}</Typography>
      {buttonText && buttonLink && (
        <Button
          color="secondary"
          onClick={() => {
            history.push(buttonLink);
          }}
        >
          {buttonText}
        </Button>
      )}
    </Box>
  );
};

const CandidatResultLabel: React.FC = () => {
  const theme = useTheme();
  const recruitment = useAtomValue(recruitmentAtom);
  const candidate = useAtomValue(candidateAtom);

  if (recruitment?.step === "canceled") {
    return (
      <StatusBox
        message="※この募集は学校によりキャンセルされました。"
        buttonText="別の授業を探す"
        buttonLink="/recruitments"
        backgroundColor={theme.palette.common.white}
      />
    );
  }

  switch (candidate?.status) {
    case "accepted":
      return (
        <StatusBox
          message="この募集の複業先生に選ばれました！"
          buttonText="成立済みの授業へ"
          buttonLink={`/teachers/mypage/lessons/${recruitment?.lesson_id}`}
          backgroundColor={theme.palette.primaryBackground?.main}
        />
      );
    case "canceled":
      return (
        <StatusBox
          message="※この募集は学校によりキャンセルされました。"
          buttonText="別の授業を探す"
          buttonLink="/recruitments"
          backgroundColor={theme.palette.common.white}
        />
      );
    case "candidate_canceled":
      return (
        <StatusBox
          message="※この募集への応募は一度キャンセルしています。"
          backgroundColor={theme.palette.common.white}
        />
      );
    case "denied":
      return (
        <StatusBox
          message="※この募集ではお見送りとなりました。"
          buttonText="別の授業を探す"
          buttonLink="/recruitments"
          backgroundColor={theme.palette.common.white}
        />
      );
    case "already_applied":
      return (
        <StatusBox
          message={`※この募集にはすでに応募済みです。${formatDateString(
            recruitment?.select_deadline || ""
          )}
          までに応募結果をご連絡します。`}
          backgroundColor={theme.palette.secondary.lighter}
        />
      );
    default:
      return null;
  }
};

export default CandidatResultLabel;
